import { Chart } from "react-charts";
import $, {  } from 'jquery';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLegend, VictoryStack, VictoryTheme } from "victory";

export function Distribution ({values,bucketWidth,name,style,title}){

    var biggest = -100000000;
    var smallest = 100000000;
    
    $.each(values,(v,value)=>{
        biggest = (value>biggest)?value : biggest;
        smallest = (value<smallest)?value : smallest;
    })

    bucketWidth = bucketWidth/2;

    do {
        bucketWidth = bucketWidth * 2;
        var lowerBound = Math.floor(smallest/bucketWidth) * bucketWidth;
        var upperBound = Math.ceil(biggest/bucketWidth) * bucketWidth;
        var numberOfBuckets = ((upperBound-lowerBound)/bucketWidth)+1;
    } while (numberOfBuckets>12)
        
    var buckets = [];
    var percents = [];
    for (var i=0;i<numberOfBuckets;i++){ buckets.push(0) ; percents.push(0)};

    var numberOfBucketedValues = 0;
    $.each(values,(v,value)=>{
        var bucket = Math.round((value-lowerBound)/bucketWidth);
        if (bucket>=0 && bucket<numberOfBuckets){
            buckets[bucket]++;
            numberOfBucketedValues++;
        }
    });

    $.each(buckets,(b,count)=>{
        percents[b] =  (Math.round((count/numberOfBucketedValues)*100));
    });

    var datums = [];
    var boundary = lowerBound;
    $.each(percents,(p,percent)=>{
        boundary += bucketWidth;
        var datum = {
            probability : percent,
        }
        datum[name] = boundary;
        datums.push(datum);
    });

    var  data = [
        {
          label: name,
          data: datums,
        }
    ];

    var primaryAxis = {
        getValue: datum => datum.count,
    };

    var secondaryAxes =[ {
        getValue: datum => datum[name],
        elementType: 'bar',
    } ];

    style.display = 'relative';


    var chart = null;
    if (datums.length > 1){
        chart = (

            <VictoryChart
            
                padding ={60}

            >
                <VictoryLegend 
                    title={title}
                    centerTitle
                    orientation="horizontal"
                    x={0} 
                    style={{ title: {fontSize: 20 }, }}
                    data={[]}
                />
                <VictoryBar
                    data={datums}
                    x={name}
                    y="probability"
                />
                <VictoryAxis crossAxis
                    domain={[0, numberOfBuckets-1]}
                    tickCount = {10}
                    // theme={VictoryTheme.material}
                    style={{
                        grid: { stroke: '#808080', strokeWidth: 0.5 },
                    }}
                    label = {name}
                    axisLabelComponent={<VictoryLabel dy={5} />}

                />
                <VictoryAxis dependentAxis crossAxis
                    domain={[0, 100]}
                    tickCount = {10}
                    // theme={VictoryTheme.material}
                    style={{
                        grid: { stroke: '#808080', strokeWidth: 0.5 },
                    }}
                    label = {'Sessions (%)'}
                    axisLabelComponent={<VictoryLabel dy={-15} />}
                   
                />
            </VictoryChart>
        );
    }
    return (
        
        <div style={style} >
             {chart}
        </div>
    )

}