
import { shallowEqual, useSelector } from "react-redux";
import { arrayToCommaSeparatedList, format1dp, format2dp, formatPcnt, formatPcnt2dp, getCookie, getQueryParams, isString, setCookie, splitCommaSeparatedList, useCookie, useProperty } from "./general";
import { sendCommand } from "./Command";
import {  useEffect, useRef,useState ,useContext } from "react";
import {  addReportRow, formatAvgWithStability, formatPcntWithStability, getStability, makeEventReport, makeReportHeader } from "./events";
import { ColumnSelector } from "./ColumnSelector";
import { MDBBtn, MDBCheckbox, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";
import { showDialog } from "./ModalDialog";
import { UxRaw } from "./us_raw";
import $ from "jquery";
import { FilterPair , FilterEditor, doesSessionPassCompFilter, CompFilterEditor, makeEmptyFilter, parseCompFilterString, getCompositeFilter} from "./FilterPair";
import { ExclOptionSelector } from "./OptionSelector";
import store from './startanStore';

function median(values) {

    values = [...values].sort((a, b) => a - b);
  
    const half = Math.floor(values.length / 2);
  
    return (values.length % 2
      ? values[half]
      : (values[half - 1] + values[half]) / 2
    );
  
}



export function UxCampaignsReport(props){

    var {accountId,period} = props;

    const firstTime = useRef(true);
    
    const uxCampaignRepCols = getCookie('uxCampaignRepCols');
    const [getChosenColumns,setChosenColumns] = useProperty(uxCampaignRepCols);
    const [getPart,setPart] = useCookie('CampaignPart','All');
    const filterCookie = 'uxCFilter';
    var filterString = getCookie(filterCookie);
    var emptyFilter = makeEmptyFilter();
    filterString = filterString?filterString:(JSON.stringify(emptyFilter));
    var [getFilter,setFilter] = useProperty(filterString)

    var columns = [
        "Key",
        "Pages",
        "Sessions",

        "Avg Page Views",

        "Avg Duration (s)",
        
        "Avg Engagement",

        "Bounced",

        "Home",
        "Collections",
        "Products",
        "Others",
    ]


    
    var events = useSelector(state => {
        var model = (state.model)?state.model:{};
        return model.events?model.events:[];
    },shallowEqual);

    // var aCampaigns = useSelector(state => {
    //     var model = (state.model)?state.model:{};
    //     return model.campaigns?model.campaigns:[];
    // },shallowEqual);

    // var campaigns = {};
    // $.each(aCampaigns,(c,campaign)=>{campaigns[campaign.name]=campaign});

    var rows =[];
    
    var csvRef = {csv};
    var headerRow = makeReportHeader(columns,getChosenColumns(),csvRef);
    var csv = csvRef.csv;

    if (events){
        var results = makeEventReport(events,period);
        
        var compositeFilter = getCompositeFilter(getFilter(),results);

        var baseReport = makeReport(null,'');

        for (var s=0;s < results.orderedSessions.length;s++){
            (function(session){


                if (doesSessionPassCompFilter(session,compositeFilter,'st')) {


                    var campaign  = session.campaign;

                    var adGroup = session.sessionSubCampaign? session.sessionSubCampaign :'Unknown';

                    var searchTerm = session.sessionSearch

                    if (campaign ) {

                        baseReport.applySessionToReport(session,[campaign,adGroup,searchTerm]);

                    }

                }

            })(results.orderedSessions[s])
        }

        var reports = [];
        baseReport.sortToList(reports);

        baseReport.setCompositeKeys();
        baseReport.compositeKey = 'Totals';


        for (var r = 0 ; r < reports.length ; r++) {
            
            (function(report){

                csv += addReportRow(rows,[

                    report.isCampaign?
                        <span style={{color:'blue',cursor:'pointer'}} onClick={()=>onCampaignClick(report.compositeKey)}>{report.compositeKey}</span>:
                        report.compositeKey,
    
                    arrayToCommaSeparatedList(report.pages),
                    report.sessions.length,
    
                    // report.orderedPageViews.length,
                    formatAvgWithStability(report,true,report.sessions,session=>{
                        return session.orderedPages.length;
                    }),
    
                    // Math.ceil(report.duration/1000),
                    formatAvgWithStability(report,true,report.sessions,session=>{return session.sessionDuration/1000;}),
    
                    // Math.ceil(report.engagement),
                    formatAvgWithStability(report,true,report.sessions,'numEngagement'),
    
                    formatPcntWithStability(report,true,report.sessions,session=>{return session.orderedPages.length == 1;}),
    
                    // formatAvgWithStability(report,true,report.sessions,session=>{ 
                    //     var typeCounts = session.pageViewTypeCounts;
                    //     return typeCounts.total()  ? (typeCounts.home / typeCounts.total() ) * 100  : 0;
                    // },v=>format2dp(v)+'%'),
    
                    // formatAvgWithStability(report,true,report.sessions,session=>{ 
                    //     var typeCounts = session.pageViewTypeCounts;
                    //     return typeCounts.total()  ? (typeCounts.collection / typeCounts.total() ) * 100  : 0;
                    // },v=>(format2dp(v)+'%')),
    
                    // formatAvgWithStability(report,true,report.sessions,session=>{ 
                    //     var typeCounts = session.pageViewTypeCounts;
                    //     return typeCounts.total()  ? (typeCounts.product / typeCounts.total() ) * 100  : 0;
                    // },v=>format2dp(v)+'%'),
    
                    // formatAvgWithStability(report,true,report.sessions,session=>{ 
                    //     var typeCounts = session.pageViewTypeCounts;
                    //     return typeCounts.total()  ? (typeCounts.other / typeCounts.total() ) * 100  : 0;
                    // },v=>format2dp(v)+'%'),
    
                    formatPcntWithStability(report,true,report.sessions,(session)=>{ 
                        return !!session.pageViewTypeCounts.home;
                    }),

                    
                    formatPcntWithStability(report,true,report.sessions,(session)=>{ 
                        return !!session.pageViewTypeCounts.collection;
                    }),
                    

                    formatPcntWithStability(report,true,report.sessions,(session)=>{ 
                        return !!session.pageViewTypeCounts.product;
                    }),

                    
                    formatPcntWithStability(report,true,report.sessions,(session)=>{ 
                        return !!session.pageViewTypeCounts.other;
                    }),

                    
    
                ],columns,getChosenColumns());

            })(reports[r]);

        }
        

    }
    
    function makeReport(baseReport,key){
        var report = {

            key : key,
            compositeKey : '',
            sessions : [],
            
            numBounces : 0,

            pages : [],             // unique
            pagesByName : {},       // unique
            orderedPageViews : [],  // all
            pageViewDurations : [],
            pageViews : 0,
            
            engagement : 0,
            duration : 0,
            
            subReportsByKey : {},
            orderedSubReports : [],

            earliestDate : 1000000000000000,


            sortToList : function (targetList,depth){
                depth = depth?depth:0;
                
                this.isCampaign = depth==1;

                if (depth>0 ) { // never show total row 
                    if (getPart()=='Just key words' ){
                        if (depth==3){
                            targetList.push(this)
                        }
                    } else {
                        targetList.push(this)
                    }
                }
                
                if (depth==0){
                    // sort campaigns by start date
                    this.orderedSubReports.sort((a,b)=>{return a.earliestDate - b.earliestDate});
                } else {
                    // sort sub campaign reports by number of sessions
                    this.orderedSubReports.sort((a,b)=>{return b.sessions.length - a.sessions.length});
                }
                for (var r = 0 ; r < this.orderedSubReports.length ; r++) {
                    this.orderedSubReports[r].sortToList(targetList,depth+1);
                }
            },

            
            applySessionToReport : function (session,keys,depth){

                if (session.sessionStart < this.earliestDate){
                    this.earliestDate = session.sessionStart;
                }
                depth = depth?depth:0;
                
                this.sessions.push(session);

                $.each(session.orderedPages,(p,page)=>{
                    
                    if (!this.pagesByName[page.pageName]){
                        this.pagesByName[page.pageName] = page.pageName;
                        this.pages.push(page.pageName);
                    }
                });

                this.orderedPageViews = this.orderedPageViews.concat(session.orderedPages);

                this.pageViews += session.orderedPages.length;
                this.engagement += session.numEngagement;
                this.duration += session.sessionLast - session.sessionStart;
                
                if (keys && keys.length){
                    
                    var part = getPart();
                    if (
                        (part=='All') || (part=='Just key words') ||
                        ((part=='Just Campaigns') && (depth <= 0)) ||
                        ((part=='With AdGroups') && (depth <= 1))                         
                    ){
                        var subReport = this.subReportsByKey[keys[0]];
                        if (!subReport){
                            subReport = makeReport(this,keys[0])    
                        }

                        subReport.applySessionToReport(session,keys.length>1?keys.slice(1):null,depth+1);
                    }
                }

            },

            setCompositeKeys : function(parentKey){
                this.compositeKey = parentKey?(parentKey+'.'+this.key):(this.key);
                $.each(this.orderedSubReports, (r,report)=>{
                    report.setCompositeKeys(this.compositeKey);
                });
            }
            
        }
        if (baseReport){
            baseReport.orderedSubReports.push(report);
            baseReport.subReportsByKey[key]=report;
        }
        return report;
    }



    function chosenColumnsChanged(value){
        setCookie('uxCampaignRepCols',value);
        setChosenColumns(value);
    }

    function copyCSV(){
        navigator.clipboard.writeText(csv);
        showDialog({
            message:"Data copied to clipboard as csv",
            ok : 'Close'
        });
    }

    function onFilterChanged(filterString){
        setCookie(filterCookie,filterString);
        setFilter(filterString)
    }

    function onKeyFilterChanged (value){
        setPart(value)
    }

    function onCampaignClick(campaignKey){
        
        sendCommand({
            modal : true,
            action:{
                objectType : 'Campaign',
                verb : 'fetchAll',
                accountId : accountId
            },
            onSucceeded : ()=>{
                var state = store.getState();
                var aCampaigns = state.model.campaigns?state.model.campaigns:[];
                var campaigns = {};
                $.each(aCampaigns,(c,campaign)=>{campaigns[campaign.name]=campaign});
                var description = campaigns[campaignKey]?campaigns[campaignKey].description:'';

                showDialog({
                    title:campaignKey,
                    message :{tag:'textarea',props:{
                        defaultValue :description,
                        onChange : event=>{description=event.target.value},
                        rows : 20,  
                        cols : 55,    
                        wrap : "soft",  
                        className : 'mx-auto',
                    }},
                        ok : 'Save',
                        cancel : true,
                        onOk : ()=>{
                            sendCommand({
                                action:{
                                    objectType : 'Campaign',
                                    verb : 'update',
                                    accountId : accountId,
                                    name : campaignKey,
                                    description : description.trim()
                                }
                            })
                        }
                });

            }
        })


        
    }

    return (<div>

        <div className="p-1 mb-2" style = {{backgroundColor:'#F0F0F0'}}>
            <div style = {{backgroundColor:'#E0E0E0'}} >
                <h3>Columns</h3>
            </div>
            <ColumnSelector className="border my-2"  columns={columns} value={getChosenColumns()} onChange={chosenColumnsChanged} ></ColumnSelector>
        </div>


        <CompFilterEditor  value={getFilter()}  report = {results}  onChange={onFilterChanged} ignore='st' ></CompFilterEditor>

        <MDBBtn style={{width:'10em'}} onClick={copyCSV} className="my-2">Copy CSV</MDBBtn>
        <h2>Campaigns Report</h2>
        <p>Averages are per session.</p>
        <p>Values in grey gives the probable error range ( % +/- ) of the preceding summarizing value. As the sample size goes up the error range should go down.</p>
        <ExclOptionSelector
            values = {['Just Campaigns','With AdGroups','Just key words','All']}
            onChange = {onKeyFilterChanged}
            value = {getPart()}
        ></ExclOptionSelector>
        <table className="border">
            <thead>
                {headerRow}
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
        <br/>
        <h3>What this table tells you.</h3>
        <p>This table summarizes information about the campaigns, ad groups and search terms of all the sessions you have selected (using the Period and Filtering panels at the top.)</p>
        <p>Information is given for each campaign individually and for ad groups within those campaigns and for all the search terms inside those ad groups.</p>
       
       
    </div>)
}