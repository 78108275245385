import { isBool , isString , isArray} from './general';
import store  from './startanStore';

import React, { } from 'react';
import { useSelector} from 'react-redux';
import {Container,Row,Col,Button,Modal} from 'react-bootstrap';
import { UxRaw } from './us_raw';

import $ from 'jquery';


export function showDialog_success(message){
    showDialog({
        title : 'Success',
        message : message,
        ok : 'Close'
    });
}
export function showDialog_problem(problem){
    showDialog({
        title : 'There was a problem',
        message : problem,
        ok : 'Close'
    });
}

export function showDialog(dialog){
    store.dispatch({
        type : 'setOverlay',
        overlay : dialog
    });
  }
  
export function hideDialog(){
    store.dispatch({
        type : 'setOverlay',
        overlay : null
    });
  }

export function Dialog() {
  
    const overlay = useSelector(state => state.overlay);

    const show = (!!overlay);
  
    function close(){
        hideDialog();
    }
    function onCancel(){
        close();
        if (overlay.onCancel){
            overlay.onCancel();
        }
    }

    function onOk(){
        close();
        if (overlay.onOk){
            overlay.onOk();
        }
    }

    var header = null;
    var footer = null; 
    var message = 'Hello World';

    if (overlay){

        message = overlay.message

        if (isArray(message)){
            message = $.map(message,m=>{return React.createElement('p',{},m)})
        } else if (!isString(message)){
            message = React.createElement(message.tag,message.props);
        }


        if (overlay.title){
            header = (
                <Modal.Header className="bg-primary text-white" show="false" key="1" >
                    <Modal.Title>{overlay.title}</Modal.Title>
                </Modal.Header> 
            );
        }
        
        if (overlay.cancel || overlay.ok){
            var buttons = [];
            if (overlay.ok){
                buttons.push (<Button variant="outline-primary" size="sm" style = {{ textTransform: 'none'}} onClick={onOk}  key="1" >
                    {(isBool(overlay.ok))?'OK':overlay.ok}
                </Button>);
            }
            if (overlay.cancel){
                buttons.push (<Button variant="outline-primary" size="sm" style = {{ textTransform: 'none'}} onClick={onCancel}  key="2">
                    {(isBool(overlay.cancel))?'Cancel':overlay.cancel}
                </Button>);
            }
            footer = (
                <Modal.Footer  key="3">
                    {buttons}
                </Modal.Footer>
            )
        }
        
        

    }

    return (
  
        <Modal show={show} backdrop='static' centered  >
          
          {header}
          
          <Modal.Body className="" style={{  }} key="2" >{message}</Modal.Body>

          {footer}

        </Modal>
      
    );
  }