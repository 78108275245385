import { configureStore } from '@reduxjs/toolkit'
import $ from 'jquery';
import { isArray, isString } from './general';
import { createStore } from 'redux'
const modelObjectAdapters = {

    // "notes" : (note=>{
    //     if (isString(note.content)){
    //         note.content=note.content?JSON.parse(note.content):undefined;
    //     }
    //     if (isString(note.tags)){
    //         note.tags = note.tags?JSON.parse(note.tags):{};
    //     }
    // })

}

var r =9;

function adaptModeObjects(key,objects){

    const adapter = modelObjectAdapters[key];
    if (adapter){
        $.map(objects,obj=>{
            return adapter(obj)
        });
    }

}




function reducer (state,action){

    state = state?state:{
        model:{},
        overlay:null,
        contextMenu : null
    };

    switch (action.type) {

        case 'modelUpdate' :{
            state = $.extend(true,{},state);
            
            var newModel = state.model;

            if (action.clearModel){

                if (action.clearModel=='*'){
                    newModel = {};
                } else {
            
                    var clearStacks = action.clearModel;
                    if (!isArray(action.clearModel)){
                    clearStacks = [action.clearModel];
                    }
                    clearStacks.map(objectType=>{
                    if (isString(objectType)){
                        delete newModel[objectType];
                    } else {
                        objectType(newModel);
                    }
                    });
                }
            }
                    
            for (var updateKey in action.modelUpdates){
        
                var update =  action.modelUpdates[updateKey];
            
                adaptModeObjects(updateKey,update);
            
                if (isArray(update)){
                    newModel[updateKey] = update;
                } else {
                    if (!newModel[updateKey] ){
                    newModel[updateKey]={}
                    }
                    $.extend( newModel[updateKey] ,update);
                }
        
            }

            state.model = newModel;
        } break;

        case 'setOverlay':{
            state = $.extend(true,{},state);
            state.overlay = action.overlay ? action.overlay : null;;
        } break;

        case 'setContextMenu':{
            state = $.extend(true,{},state);
            state.contextMenu = action.contextMenu?action.contextMenu:null;
        }break;

    }

    return state;
}



const store = createStore(reducer);

// const store = configureStore({
//     reducer : reducer,
//     preloadedState : { 
//         model : {},
//         overlay : null,
//         contextMenu : null
//     }
// });

export default store

