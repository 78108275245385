import { UniqueStrings, arrayToNamed, format1dp, format2dp, formatPcnt, isArray, isNumber, isString } from "./general";
import $, { isPlainObject } from "jquery";

class PageView {
    pageName = "";
    pageViewId = 0;
    numEngagement = 0;  
    pageStart = 0;  
    pageLast =0;
    activeDuration = 0;
    session = null;
    micro = false;
}

class Session{
    userId = -1;
    sessionEvent= null;
    sessionId = 0;
    numPages = 0;
    pageId = 0;
    page = "";
    numEngagement = 0;  
    sessionStart = 0;  
    sessionLast =0;
    sessionDuration = 0;
    pages = {};         // page views by pageview id
    orderedPages = [];  // all page views
    pageLastNames = {}; // unique page names
    orderedPageLastNames = [];
    pageViewTypeCounts = {
        home : 0,
        collection : 0,
        product : 0,
        other : 0,
        total : function (){
            return this.home + this.collection + this.product + this.other;
        }
    };

    engaged = false;
    micro = false;
    campaign ='';
    timedOut = false;
    questionsAnswered = 0;
    addClickId = 0; // e.g. gclid supplied by google adds
    events = [];
    onPageId = 0; // id of the most recent page view 
    onPageAt = 0; // timestamp of most recent onPageId event

    sessionSubCampaign = '';
    sessionSearch = '';

    newUser = false;
}

class User {
    userId = -1;
    userStart = 0;
    userLast =0;
    sessions = {};
    orderedSessions = [];
    pages = {};
    orderedPages = [];
    numEngagement = 0;  
}

class Day {
    users={}
    orderedUsers =[];
    sessions={};
    orderedSessions = [];
    pages = {};
    orderedPages = [];
    numEngagement = 0;
}

function logEvent(event,prefix){
    if (prefix){
        console.log(prefix + " : " + event);
    } else {
        console.log(event);
    }
}

export function cleanupEvents(events){

    console.log('CLEANING EVENTS');

    var badUserIds={};
    var badSessionIds={};
    var badPageIds={};

    var userStarts={};
    var sessionStarts={};
    var pageStarts={};

    // var referers = {};
    // var orderedReferers  = [];

    for ( var i=0 ; i < events.length ; i++ ) { 
        var event = events[i]
        switch (event.name){
            case 'user_start' :{
                if (userStarts[event.userId]) {
                    badUserIds[event.userId] = true;
                } else {
                    userStarts[event.userId]=true;
                } 
            } break;
            case 'session_start' :{
                if (sessionStarts[event.sessionId]) {
                    badSessionIds[event.sessionId] = true;
                } else {
                    sessionStarts[event.sessionId]=true;
                } 
            } break;
            case 'page_view_start' :{
                if (pageStarts[event.pageViewId]) {
                    badPageIds[event.pageViewId] = true;
                } else {
                    pageStarts[event.pageViewId] = true;
                } 
            } break;
        }        
    }

    var sessionData = {};
    var aSessionData =[];
    var cleanEvents = [];
    for ( var i=0 ; i < events.length ; i++ ) { 
        var event = events[i];
        if (!(
            badUserIds[event.userId] ||
            badSessionIds[event.sessionId] ||
            badPageIds[event.pageViewId] 
        )){

            var sd = sessionData[event.sessionId];
            
            if (!sd){
                sd = {
                    start : event.timestamp ,
                    end : event.timestamp ,
                    firstPage : null,
                    lastPage : null,
                    events : [],
                    referer : event.sessionReferrer ,
                }
                sessionData[event.sessionId] = sd;
                aSessionData.push(sd);
            }
            if (sd.start > event.timestamp){
                sd.start = event.timestamp
            }
            if (sd.end < event.timestamp){
                sd.end = event.timestamp
            }
            if (event.userId){
                sd.userId = event.userId;
            }
            if (event.sessionId){
                sd.sessionId = event.sessionId;
            }
            if (event.pageName){
                if (!sd.firstPage){
                    sd.firstPage = event.pageName;
                }
                sd.lastPage = event.pageName;
            }
            cleanEvents.push(event);
            sd.events.push(event);
            
        } else {
            logEvent(event,"Excluding repeated id creation");
        }
    } 

    if (true){
        // concatenate heel and toe sessions to account for the cookie /path bug.
        aSessionData.sort((a,b)=>a.start-b.start);
        $.each(aSessionData,(i,sd)=>{
            if (i){
                var sdp = aSessionData[i-1];
                var gap = sd.start - sdp.end;
                if ( (sd.referer=='direct') && ( sdp.start < 1721649010976 ) &&  ( gap<10000 ) && (gap >0) && ( sdp.lastPage != sd.firstPage) ) {
                    // we assume that these are part of the same session
                    console.log ('Combining : ' + sd.userId + ' with ' + sdp.userId)
                    $.each(sd.events,(e,event)=>{
                        event.userId = sdp.userId;
                        event.sessionId = sdp.sessionId;
                        event.sessionReferrer = sdp.referer;
                        if ( (event.name == 'user_start') || (event.name == 'session_start') ) {
                            event.name = 'none';
                        }
                    })
                    sd.userId = sdp.userId;
                    sd.sessionId = sdp.sessionId;
                    sd.referer = sdp.referer;
                }
            }
        })
    }




    // decorate the events

    $.each(cleanEvents,(e,event)=>{
        var referer = event.sessionReferrer?event.sessionReferrer:'direct';
        var idot = referer.indexOf('.');
        if (idot>0 && idot<referer.length-1){
            var prefix = referer.substring(0,idot);
            if (prefix=='www' ||  prefix=='l' ||  prefix=='lm'  ||  prefix=='m'  ||  prefix=='ml'){
                referer = referer.substring(idot+1);
            }
        }
        var idot = referer.lastIndexOf('.');
        if (idot>0 && idot<referer.length-1){
            referer = referer.substring(0,idot);
            var idot = referer.lastIndexOf('.');
            if (idot>0 && idot<referer.length-1){
            var suffix = referer.substring(idot+1);
                if (suffix=='co'){
                    referer = referer.substring(0,idot)
                }
            }
        }

        event.sessionReferrer = referer;
        
        event.sessionCampaign = event.sessionCampaign?event.sessionCampaign:'';
        if (event.sessionCampaign){
            var ii = 0;
        }
    });


    return cleanEvents;

}

export function getPageType(pageName){
    if (pageName){
        var bits = pageName.split('/');
        if (bits[0]=='home') {
            return 'home';
        } else if (bits[0]=='collections'){
            return 'collection';
        } else if (bits[0]=='products') {
            return 'product';
        } else {
            return 'other'
        }
    } else {
        return 'none';
    }
}

function getPageLastName(pagePath){
    if (pagePath){
        var bits = pagePath.split('/');
        return bits[bits.length-1];
    } else {
        return '';
    }
}
export function makeEventReport(events,period){

    // scan for bot generated nonsense

    events.sort((a,b)=>a.timestamp-b.timestamp);
    var events = cleanupEvents(events);

    var sessions = {};
    var orderedSessions = [];

    var pages = {};
    var orderedPages = [];

    var pageLastNames= {};
    var orderedPageLastNames = [];

    var users = {};
    var orderedUsers = [];

    var days = {};
    var orderedDays = [];

    var referers = {};
    var orderedReferers = [];

    var campaigns = {};
    var orderedCampaigns = [];

    var subCampaigns = {};
    var orderedSubCampaigns = [];

    var searchTerms = {};
    var orderedSearchTerms = [];

    var page;
    var session;
    var user;
    var day

    var newUserSession = 0;

    for ( var ev=0 ; ev < events.length ; ev++ ) {

        var event = events[ev];

        if (event.timestamp >= period.from && event.timestamp <= period.to) {
            var dayDate = new Date(event.timestamp);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var dateKey = dayDate.getDate() +":"+months[dayDate.getMonth()] + ":" + dayDate.getFullYear();

            day = days[dateKey];
            if (!day){
                day = new Day();
                day.date = dateKey;
                days[dateKey] = day;
                orderedDays.push(day);
            }


            var user = users[event.userId];
            if (!user){
                user = new User();
                users[event.userId]=user;
                orderedUsers.push(user);

                user.userId = event.userId;
                user.userStart = event.timestamp;

            }
            user.userLast = event.timestamp;

            
            var session = sessions[event.sessionId];

            if (!session){
                if (event.name == 'user_start'){
                    newUserSession = event.sessionId;
                }
                
                if (event.name=='session_start'){
                
                    session = new Session();
                    sessions[event.sessionId]=session;
                    orderedSessions.push(session);

                    session.sessionEvent = event;
                    session.sessionId = event.sessionId;
                    session.userId = event.userId;
                    session.sessionStart = event.timestamp;
                    session.sessionLast = event.timestamp;
                    session.sessionDuration = session.sessionLast - session.sessionStart;
                    session.page = event.pageName;
                    session.sessionReferrer = event.sessionReferrer;
                    session.campaign = event.sessionCampaign;
                    session.addClickId = event.addClickId;
                    
                    if (newUserSession == event.sessionId){
                        session.newUser = true;
                    }
                    if (event.data){
                        var sessionData = JSON.parse(event.data);
                        session.sessionSubCampaign = sessionData.sessionSubCampaign?sessionData.sessionSubCampaign:'';
                        session.sessionSearch = sessionData.sessionSearch?sessionData.sessionSearch:'';
                    }

                    user.sessions[event.sessionId]=session;
                    user.orderedSessions.push(session);

                    day.sessions[event.sessionId] = session;
                    day.orderedSessions.push(session);

                    if(!day.users[event.userId]){
                        day.users[event.userId] = user;
                        day.orderedUsers.push(user);
                    }

                    if (!referers[session.sessionReferrer]){
                        referers[session.sessionReferrer] = true;
                        orderedReferers.push(session.sessionReferrer);
                    }

                    if (!!session.campaign){

                        var campaign = campaigns[session.campaign];

                        if (!campaign){
                            campaign = {
                                name : session.campaign,
                                subCampaigns : {},
                                orderedSubCampaigns : [],
                                addOrderedSubCampaignNames : function(target){
                                    var byName = arrayToNamed(target);
                                    $.each(this.orderedSubCampaigns,(sc,subCampaign)=>{
                                        if (!byName[subCampaign.name]){
                                            byName[subCampaign.name]=true;
                                            target.push(subCampaign.name);
                                        }
                                    });
                                    return target;
                                },
                                addOrderedSearchTerms : function(target){
                                    $.each(this.orderedSubCampaigns,(sc,subCampaign)=>{
                                        subCampaign.addOrderedSearchTerms(target);
                                    });
                                    return target;
                                }
                            };
                            campaigns[session.campaign] = campaign;
                            orderedCampaigns.push(session.campaign);
                        }

                        if (session.sessionSubCampaign){

                            var subCampaign = campaign.subCampaigns[session.sessionSubCampaign];

                            if ( !subCampaign ) {

                                subCampaign = {
                                    name : session.sessionSubCampaign,
                                    searchTerms : {},
                                    orderedSearchTerms : [],
                                    addOrderedSearchTerms : function(target){
                                        var byName = arrayToNamed(target);
                                        $.each(this.orderedSearchTerms,(st,searchTerm)=>{
                                            if (!byName[searchTerm.name]){
                                                byName[searchTerm.name]=true;
                                                target.push(searchTerm.name);
                                            }
                                        });
                                        return target;
                                    },
                                }

                                campaign.subCampaigns[session.sessionSubCampaign] = subCampaign;
                                campaign.orderedSubCampaigns.push(subCampaign);

                                subCampaigns[session.sessionSubCampaign] = subCampaign;
                                orderedSubCampaigns.push(session.sessionSubCampaign);
                            }
    
                            if (session.sessionSearch && !subCampaign.searchTerms[session.sessionSearch]){

                                subCampaign.searchTerms[session.sessionSearch] = true;
                                subCampaign.orderedSearchTerms.push(session.sessionSearch);

                                searchTerms[session.sessionSearch] = true;
                                orderedSearchTerms.push(session.sessionSearch);
                            }

                            
                        }
                        

                    }


                    
                     



                }
            }

            if (session){

                if (!session.timedOut){

                    var gap = event.timestamp-session.sessionLast;
                    if (gap > 90 * 1000){
                        session.timedOut = true;
                    } else {
                        session.events.push(event);
                        session.sessionLast = event.timestamp;
                        session.sessionDuration = session.sessionLast - session.sessionStart;

                        page = pages[event.pageViewId];

                        
                        if (!page){
                            page = new PageView();
                            pages[event.pageViewId] = page;
                            orderedPages.push(page);
                            page.session = session;

                            page.pageViewId = event.pageViewId;
                            page.pageName = event.pageName;
                            page.pageStart = event.timestamp;
                            page.pageLast = event.timestamp;

                            session.numPages++;
                            
                            session.pages[event.pageViewId] = page;
                            session.orderedPages.push(page);

                            var pageLastName = getPageLastName(event.pageName);

                            session.pageLastNames[pageLastName] = true;
                            if (!pageLastNames[pageLastName]){
                                pageLastNames[pageLastName] = true;
                                orderedPageLastNames.push(pageLastName);
                            }
                            user.pages[event.pageViewId] = page;
                            user.orderedPages.push(page);
                            
                            day.pages[event.pageId] = page;
                            day.orderedPages.push(page);

                            var pageType = getPageType(page.pageName);
                            session.pageViewTypeCounts[pageType]= session.pageViewTypeCounts[pageType]?session.pageViewTypeCounts[pageType]:0;
                            session.pageViewTypeCounts[pageType]++ ;
                            

                        }
                        
                        if (session.onPageId == page.pageViewId){
                            page.activeDuration += (event.timestamp - session.onPageAt);

                        }
                        session.onPageId = page.pageViewId;
                        session.onPageAt = event.timestamp;

                        page.pageLast = Math.max(event.timestamp,page.pageLast );

                        if (page.activeDuration>(page.pageLast-page.pageStart)){
                            var ii=0;
                        }

                        if (event.name=='engaged'){
                            session.numEngagement++;
                            page.numEngagement++;
                            user.numEngagement++;
                            day.numEngagement++;
                            session.engaged = true; 
                        }
                        if (event.name=='leaving'){
                            session.engaged = true;
                            page.left = true;
                        }

                        if (event.name == 'questionnaire_answer'){
                            session.questionsAnswered ++;
                        }

                        var secs =  Math.round((event.timestamp - session.sessionStart) /100);
                        var  mins = Math.floor(secs/600);
                        secs = secs-(mins*600);
                        event.sessionTime = ((mins>0.001)?(mins+"::"):'').toString()+ (Math.round(secs/10));
                        var ii=0;
                    }
                }
            }
        }
    }

    function getAppropriateSubCampaignNames(campaignNames){
        var campaigns = [];
        $.each(campaignNames,(i,campaignName)=>{
            if (this.campaigns[campaignName]){
                campaigns.push(this.campaigns[campaignName]);
            }
        })

        var uSubCampaignNames = new UniqueStrings();
        
        $.each(campaigns,(c,campaign)=>{
            $.each(campaign.orderedSubCampaigns,(c,subCampaign)=>{
                uSubCampaignNames.add(subCampaign.name);
            });
        });

        return uSubCampaignNames.ordered;
    }

    function getAppropriateSearchTermNames(subCampaignNames){
        var subCampaigns = [];
        $.each(subCampaignNames,(i,subCampaignName)=>{
            if (this.subCampaigns[subCampaignName]){
                subCampaigns.push(this.subCampaigns[subCampaignName]);
            };
        })

        var uSearchTermNames = new UniqueStrings();
        
        $.each(subCampaigns,(c,subCampaign)=>{
            $.each(subCampaign.orderedSearchTerms,(c,searchTerm)=>{
                uSearchTermNames.add(searchTerm);
            });
        });

        return uSearchTermNames.ordered;
    }


    var report = {
        users,
        orderedUsers,
        sessions,
        orderedSessions,
        pages,
        orderedPages,
        pageLastNames,
        orderedPageLastNames,
        days,
        orderedDays,
        orderedReferers,
        referers,
        orderedCampaigns,
        campaigns,
        subCampaigns,
        orderedSubCampaigns,
        searchTerms,
        orderedSearchTerms,
        getAppropriateSubCampaignNames: getAppropriateSubCampaignNames,
        getAppropriateSearchTermNames
    };

    markSessionsWithMicroPages(report);

    return report;
}

function markSessionsWithMicroPages(report){

    
    $.each(report.orderedPages, ( p, page ) => {
        
        var duration = page.pageLast-page.pageStart;
        if (page.left && ( duration < 100) ){
            page.micro = true;
            page.session.micro = true;
        }
        //console.log( page.session.sessionId+" : "+duration+ " : " + page.micro)
    });

}

function makeColNames(columns){
    var colNames = [];
    $(columns).each((i,column)=>{
        if (isString(column)){
            colNames.push(column);
        } else {
            colNames.push(column[0]);
        }
    });
    return colNames;
}

function csvToObject(s){
    
    if (!s){
        return null;
    }

    var obj={};
    const n = s.split(',');
    for (var i = 0;i<n.length;i++){
        var v = n[i];
        if (v!=""){
            obj[v]= true;
        }
    }
    return obj;

}

export function addReportRow(rows,cell_data,columns,chosen){

    var csv = [];
    chosen = csvToObject(chosen);
    
    var colNames = makeColNames(columns);
    
    var cells = [];

    for (var c = 0 ; c<cell_data.length;c++){
        if (!chosen || chosen[colNames[c]]){
            var cellValue = isArray(cell_data[c])?cell_data[c][0]:cell_data[c];
            var csvValue = isArray(cell_data[c])?cell_data[c][1]:cell_data[c];
        
            cells.push(<td className="border p-1" key={c+1}  >{cellValue}</td>);
            if (!$.isPlainObject(csvValue)){
                csv.push(csvValue?csvValue:' ')
            }
        }
    }
    rows.push(<tr  key={rows.length}  style={{backgroundColor:'white'}} >{cells}</tr>);
    return csv.join(',') + '\r\n';
}

export function makeReportHeader(header_data,chosen,csvObj){
    
    var csv = [];

    chosen = csvToObject(chosen);
    var colNames = makeColNames(header_data);

    var cells = [];

    for (var c = 0 ; c<header_data.length;c++){
        if (!chosen || chosen[colNames[c]]){
            var col = header_data[c];
            var title = isString(col)?col:col[0];
            var style = isString(col)?{}:{width :col[1]};
            cells.push(<th className="border p-1" key={c+1}  style={style}>{title}</th>);
            csv.push(title);
        }
        
    }

    var styles = {
        backgroundColor : '#F0F0F0'
    };

    if (csvObj){
        csvObj.csv = csv.join(',')+ '\r\n';;
    }
    return  <tr  key={'header'} className="startanSticky" style={styles} >{cells}</tr>
}

export function getStability ( report , source , firstPass , secondPass ){

    firstPass = firstPass?firstPass:function(){};

    var context = {};

    $.each(source,(i,item)=>{
        firstPass(report, source,context,i,item);
    });

    var values = $.map(source,(item,i)=>{
        return secondPass(report ,source,context,i,item);
    });

    var movements = [];
    var lastValue = 0;
    $.each(values,(v,value)=>{
        if (v){
            var absDif = Math.abs(value-lastValue);
            movements.push((absDif/lastValue)*100);
        }
        lastValue = value;
    });

    if (movements.length<2){
        return '-'
    }

    var sampleSize = Math.max(4,Math.floor(movements.length/10));
    sampleSize = Math.min (movements.length,sampleSize);
    var totalSampleMovement = 0;
    
    for (var i = movements.length - sampleSize ;i<movements.length;i++){
        totalSampleMovement+=movements[i];
    }

    var stability = totalSampleMovement/sampleSize;
    
    return stability;
} 


export function formatAvgWithStability(results,includeStability,sourceArray,accessor,formatter){

    var lastValue = 0;
    var values = [];
    var improvingAverages = [];
    var stability = getStability(results,sourceArray,null,                   
        (results ,orderedPageViews,context,i,item)=>{
            var value = isString (accessor)?item[accessor]:accessor(item,i,context);
            values.push(value);
            context.total = context.total?context.total:0;
            context.total += value;
            lastValue = context.total / (i+1);
            improvingAverages.push(lastValue);
            return lastValue;
        }
    );

    var f2dp = format2dp(lastValue);
    var formatted = formatter?formatter(lastValue):f2dp; 
    if (includeStability) {
        return [ 
            (<span> {formatted} <span style={{color:'#D0D0D0'}}>
                ({ isNumber(stability)?format1dp( stability):stability })
            </span></span>),
            f2dp
        ];
    } else {
        return formatted;
    }

    
}

export function formatPcntWithStability(results,includeStability,sourceArray,tester){

    var lastValue = 0;
    var values = [];
    var improvingPercents = [];
    var stability = getStability(results,sourceArray,null,                   
        (results ,orderedPageViews,context,i,item)=>{
            context.passed = context.passed?context.passed:0;

            if ( tester(item) ) {
                context.passed++;
            }
            lastValue = context.passed/(i+1);
            values.push(lastValue);
            improvingPercents.push(lastValue);
            return lastValue;
        }
    );

    var formatted = formatPcnt(lastValue); 
    if (includeStability) {
        return [
            (<span> {formatted} <span style={{color:'#D0D0D0'}}>
                ({ isNumber(stability)?format1dp( stability):stability })
            </span></span>),
            lastValue
        ]
    } else {
        return [formatted,lastValue];
    }


}