
import { MDBBtn, MDBCheckbox, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";
import { CheckBox, arrayToNamed, getCookie, isString, setCookie, splitCommaSeparatedList, useCookie, useParameter, useProperty } from "./general";
import $ from "jquery";
import { showDialog } from "./ModalDialog";
import { OptionSelector } from "./OptionSelector";
import { ExOptionSelector } from "./ExOptionSelector";
import { flushSync } from "react-dom";

export function makeEmptyFilter(){
    return {
        mode : 'Simple',
        filterOptions : {
            Simple : {
                name:'Simple',
                type:'Simple',
                filterFields : '',
                props : {
                    referers : [],
                    referersByName : {},
                    campaigns : [],
                    campaignsByName: {},    
                    subCampaigns : [],
                    subCampaignsByName: {},    
                    searchTerms : [],
                    searchTermsByName: {},    
                    pages : [],
                    pagesByName: {},    
                }
            },
            Advanced : {
                name:'Advanced',
                type:"Advanced",
                props : {
                    advanced : []
                }
            },
        },
    }
}

export function doesSessionPassCompFilter(session,compFilter,ignore){
    
    ignore = ignore?ignore:'';

    var offerSubCampaignFiltering = ignore.indexOf('s')>=0;
    var offerKewWordFiltering = ignore.indexOf('t')>=0;

    var opt = compFilter.filterOptions[compFilter.mode];
    if (compFilter.exclNominalSessions){
        if (session.sessionLast - session.sessionStart <= 1*1000){
            return false;
        }

        if (!session.newUser && session.numEngagement==0){
            return false;
        }
    }
    if (opt.type=='Advanced'){
        return passesAdvancedFilter(
            session.sessionEvent.sessionReferrer,
            session.sessionEvent.sessionCampaign,
            opt.props.advanced);
    } else {

        var {filterByReferers ,filterByCampaigns,filterBySubCampaigns,filterByPages} = extractFilterFields(compFilter);

        var referers = opt.props.referers?opt.props.referers:[];
        var referersByName = opt.props.referersByName?opt.props.referersByName:[];
        
        var campaigns = opt.props.campaigns?opt.props.campaigns:[];
        var campaignsByName = opt.props.campaignsByName?opt.props.campaignsByName:[];

        var subCampaigns = opt.props.subCampaigns?opt.props.subCampaigns:[];
        var subCampaignsByName = opt.props.subCampaignsByName?opt.props.subCampaignsByName:[];

        var searchTerms = opt.props.searchTerms?opt.props.searchTerms:[];
        var searchTermsByName = opt.props.searchTermsByName?opt.props.searchTermsByName:[];

        var pages = opt.props.pages?opt.props.pages:[];
        var pagesByName = opt.props.pagesByName?opt.props.pagesByName:[];

        var referersSatisfied = !filterByReferers || ((referers.length==0) || referersByName[session.sessionEvent.sessionReferrer]);
        var campaignSatisfied = !filterByCampaigns || ((campaigns.length==0) || campaignsByName[session.sessionEvent.sessionCampaign]);

        var pageMatchedLastName = false;
        $.each(pages,(p,page)=>{
            pageMatchedLastName = pageMatchedLastName || session.pageLastNames[page];
        });
    
        var pagesSatisfied = !filterByPages || ((pages.length==0) || pageMatchedLastName);
        var subCampaignSatisfied = !filterBySubCampaigns || (((subCampaigns.length==0) || subCampaignsByName[session.sessionSubCampaign]) || offerSubCampaignFiltering);
        var searchTermSatisfied = !filterBySubCampaigns || (((searchTerms.length==0) || searchTermsByName[session.sessionSearch]) || offerKewWordFiltering );
        
        return  referersSatisfied && campaignSatisfied && subCampaignSatisfied && searchTermSatisfied && pagesSatisfied;             
    }
}

export function parseCompFilterString( filterString ){

    if (filterString){
        var comp = JSON.parse(filterString);
        if(!comp.filterOptions[comp.mode]){
            comp.mode = 'Simple';
        }
        return comp;
    } else {
        return makeEmptyFilter();
    }
}

export function getCompositeFilter(filter,report){

    
    var compFilter =  parseCompFilterString(filter);

    cleanFilter(compFilter,report);

    return compFilter;
}

function passesAdvancedFilter (referrer,campaign,filter){

    for (var p = 0;p<filter.length;p++){
        if ((function(pair){
            var [r,c] = pair;
            if (r!='Any'){
                if (r!=referrer){
                    return false;
                }
            }
            if (c!='Any'){
                if (c!=campaign){
                    return false;
                }
            }
            return true;
        })(filter[p])){
            return true
        }
       
       
    }
    return false;
}

export function cleanFilter(filter,filterableSet){

    if (filterableSet.orderedReferers){
        filterableSet = getFilterableSet(filterableSet,filter);
    }

    var {referers,campaigns,subCampaigns,searchTerms,pages}= filterableSet ;

    referers = arrayToNamed(referers);
    campaigns = arrayToNamed(campaigns);
    subCampaigns = arrayToNamed(subCampaigns?subCampaigns:[]);
    searchTerms = arrayToNamed(searchTerms?searchTerms:[]);
    pages = arrayToNamed(pages?pages:[]);

    var simpleProps = filter.filterOptions['Simple'].props;

    simpleProps.pages= simpleProps.pages?simpleProps.pages:[];
    
    simpleProps.filterFields = simpleProps.filterFields?simpleProps.filterFields:'';
    simpleProps.referers = $.grep(simpleProps.referers,referer=>!!referers[referer]);
    simpleProps.campaigns = $.grep(simpleProps.campaigns,campaign=>!!campaigns[campaign]);
    simpleProps.pages = $.grep(simpleProps.pages,page=>!!pages[page]);
    simpleProps.subCampaigns = $.grep(simpleProps.subCampaigns?simpleProps.subCampaigns:[],subCampaign=>!!subCampaigns[subCampaign]);
    simpleProps.searchTerms = $.grep(simpleProps.searchTerms?simpleProps.searchTerms:[],searchTerm=>!!searchTerms[searchTerm]);

    return filter;
}

export function getFilterableSet(report,filter){

    var referers = report.orderedReferers; 
    var campaigns = report.orderedCampaigns;  
    var subCampaigns = report.getAppropriateSubCampaignNames(filter.filterOptions['Simple'].props.campaigns);//orderedSubCampaigns;
    var searchTerms = report.getAppropriateSearchTermNames(subCampaigns);// orderedSearchTerms;
    var pages = report.orderedPageLastNames;

    return {
        referers,
        campaigns,
        subCampaigns,
        searchTerms,
        pages,
    };

}

function extractFilterFields(filter){
    
    if (filter && filter.filterOptions){
    
        var simpleOption = filter.filterOptions['Simple'];
        if (simpleOption){
            var filterFields = simpleOption.filterFields
            if (isString(filterFields)){
                return {
                    filterByReferers : filterFields.indexOf('referers')>=0,
                    filterByCampaigns : filterFields.indexOf('campaigns')>=0,
                    filterBySubCampaigns : filterFields.indexOf('campaign info')>=0,
                    filterByPages : filterFields.indexOf('pages')>=0,
                }
            }
        } 
    }
    return {
        filterByReferers : false,
        filterByCampaigns : false,
        filterBySubCampaigns : false,
        filterByPages : false,
    }

}

export function CompFilterEditor({className,style,value,onChange,report,ignore}){

    var ignore = ignore?ignore:'';

    var offerSubCampaignFiltering = ignore.indexOf('s')==-1;
    var offerKewWordFiltering = ignore.indexOf('t')==-1;
    var offerAnyCampaignInfoFiltering = offerSubCampaignFiltering || offerKewWordFiltering;
    var [getValue,setValue] = useProperty(value);


    var filter = getFilter();
    var filterOption = filter.filterOptions[filter.mode];

    var filterableSet= getFilterableSet(report,filter);

    var {referers,campaigns,subCampaigns,searchTerms,pages} = filterableSet;


    function getFilter(){

        var f = parseCompFilterString(getValue());

        if (f && f.filterOptions.Simple){
            var props =  f.filterOptions.Simple.props;

            props.pages = props.pages?props.pages:[];
            props.pagesByName = props.pagesByName?props.pagesByName:[];
        }
        return f;
    }

    function changeFilter(f){
        cleanFilter(f,filterableSet);

        var value = JSON.stringify(f);
        setValue(value);
        onChange(value);
    }

    function exclNominalChanged(value){
        var f = getFilter();
        f.exclNominalSessions = value;
        changeFilter(f);
    }

    function chosenReferersChanged(value){
        var f = getFilter();
        var fopt = f.filterOptions[f.mode];
        var [referers] = splitCommaSeparatedList(value);
        fopt.props.referers = referers;
        fopt.props.referersByName = {};
        $.each(referers,(r,referer)=>{
            fopt.props.referersByName[referer] = referer;
        });
        changeFilter(f);
    }

    function chosenPagesChanged(value){
        var f = getFilter();
        var fopt = f.filterOptions[f.mode];
        var [pages] = splitCommaSeparatedList(value);
        fopt.props.pages = pages;
        fopt.props.pagesByName = {};
        $.each(pages,(p,page)=>{
            fopt.props.pagesByName[page] = page;
        });
        changeFilter(f);
    }

    function chosenCampaignsChanged(value){
        var f = getFilter();
        var fopt = f.filterOptions[f.mode];
        var [campaigns] = splitCommaSeparatedList(value);
        
        fopt.props.campaigns = campaigns;
        fopt.props.campaignsByName = {};
        $.each(campaigns,(c,campaign)=>{
            fopt.props.campaignsByName[campaign] = campaign;
        });


        changeFilter(f);
    }

    function chosenSubCampaignsChanged(value){
        var f = getFilter();
        var fopt = f.filterOptions[f.mode];
        var [subCampaigns] = splitCommaSeparatedList(value);
        
        fopt.props.subCampaigns = subCampaigns;
        fopt.props.subCampaignsByName = {};
        $.each(subCampaigns,(c,subCampaign)=>{
            fopt.props.subCampaignsByName[subCampaign] = subCampaign;
        });

        changeFilter(f);
    }

    
    function chosenSearchTermsChanged(value){
        var f = getFilter();
        var fopt = f.filterOptions[f.mode];
        var [terms] = splitCommaSeparatedList(value);
        
        fopt.props.searchTerms = terms;
        fopt.props.searchTermsByName = {};
        $.each(terms,(c,term)=>{
            fopt.props.searchTermsByName[term] = term;
        });

        changeFilter(f);
    }

    function filterFieldsChanged(filterFields){
        var f = getFilter();
        if (f){
            var simpleOptions = f.filterOptions['Simple'];
            
            simpleOptions.filterFields = filterFields;
            changeFilter(f);
        }
    }

    function getFilterFields(){
        var f = getFilter();
        if (f && f.filterOptions['Simple']){
            var simpleOptions = f.filterOptions['Simple'];
            return simpleOptions.filterFields 
        }
        return '';
    }
    
    function advancedFilterChanged(value){
        var f = getFilter();
        var fopt = f.filterOptions[f.mode];
        fopt.props.advanced = parseAdvancedFilter(value);
        changeFilter(f);
    }

    function changeFilterMode(mode){
         var f = getFilter();
        f.mode = mode;
        changeFilter(f);
    }



    var filterOptions = [];

    $.each(filter.filterOptions,(i,option)=>{
        filterOptions.push(option.name)
    });

    var {filterByReferers ,filterByCampaigns,filterBySubCampaigns,filterByPages} = extractFilterFields(filter);
    
    var activeFilter = (filterOption.type=='Advanced')?
        (<div style = {{ }} className="my-2">
            <AdvancedFilterEditor value={ stringifyFilter( filterOption.props.advanced )  } referers={referers} campaigns={campaigns} onChange={value=>advancedFilterChanged(value)} ></AdvancedFilterEditor>
        </div>):
        (<div style = {{ }}>
            <div className="hstack " key="0" style={{display:(filterByReferers?'':'none')}}  >
                <div 
                    key="0"
                    className=""
                    style= {{width:'8em'}} 
                >
                    Referers : 
                </div>
                <div className="" key="1" >
                    <OptionSelector referers = {referers} value={filterOption.props.referers.join(',')}  onChange={chosenReferersChanged}  ></OptionSelector>
                </div>
            </div>
            <div className="hstack " key="1" style={{display:(filterByPages?'':'none')}}  >
                <div 
                    key="0"
                    className=""
                    style= {{width:'8em'}} 
                >
                    Pages : 
                </div>
                <div className="" key="1" >
                    <OptionSelector referers = {pages} value={filterOption.props.pages.join(',')}  onChange={chosenPagesChanged}  ></OptionSelector>
                </div>
            </div>
            <div className="hstack " key="2" style={{display:(filterByCampaigns?'':'none')}} >
                <div 
                    className=""
                    style= {{width:'8em'}} 
                    key="0"
                >
                    Campaigns : 
                </div>
                <div className="" key="1" >
                    <OptionSelector referers = {campaigns} value={filterOption.props.campaigns.join(',')}  onChange={chosenCampaignsChanged}  ></OptionSelector> 
                </div>
            </div> 
            <div className="hstack " key="3" style={{display:((filterBySubCampaigns && subCampaigns && subCampaigns.length  && offerSubCampaignFiltering)?'':'none')}} >
                <div 
                    className=""
                    style= {{width:'8em'}} 
                    key="0"
                >
                    Sub Campaigns : 
                </div>
                <div className="" key="1" >
                    <OptionSelector referers = {subCampaigns} value={filterOption.props.subCampaigns?filterOption.props.subCampaigns.join(','):''}  onChange={chosenSubCampaignsChanged}  ></OptionSelector> 
                </div>
            </div> 
            <div className="hstack " key="4" style={{display:((filterBySubCampaigns && searchTerms && searchTerms.length && offerKewWordFiltering)?'':'none')}}>
                <div 
                    className=""
                    style= {{width:'8em'}} 
                    key="0"
                >
                    Searches : 
                </div>
                <div className="" key="1" >
                    <OptionSelector referers = {searchTerms} value={filterOption.props.searchTerms?filterOption.props.searchTerms.join(','):''}  onChange={chosenSearchTermsChanged}  ></OptionSelector> 
                </div>
            </div> 

        </div>);




    function onDelete(){
        var f = getFilter();
        var fopt = filter.filterOptions[f.mode];
        delete f.filterOptions[fopt.name];
        changeFilter(f);
    }

    function onSave(){

        var name = '';

        showDialog({
            title : 'Enter new filter name.',
            message :{tag:'input',props:{onChange : event=>{name=event.target.value} }},
            ok : 'Save',
            cancel : true,
            onOk : ()=>{
                var f = getFilter();
                var fopt = f.filterOptions[f.mode];
                name = name.trim();
                if (!f.filterOptions[name] && name){
                    var newOption = $.extend({},fopt);
                    newOption.name = name;
                    f.filterOptions[name]=newOption;
                    f.mode = name;
                    changeFilter(f);
                }
            }
        })

    }

    var managementButton = ( filterOption.name=='Simple' || filterOption.name=='Advanced') ? (
        <div></div>
        // <MDBBtn style={{textTransform: 'lowercase', float : 'right' }} className="small p-1 ms-4  border" color="success" onClick={onSave}>Save as Named Filter</MDBBtn>
    ) : (
        <MDBBtn style={{textTransform: 'lowercase' , float : 'right'}} className="small p-1 ms-4  border" color="danger" onClick={onDelete}>Delete Named Filter</MDBBtn>
    );

    var xxx = getFilterFields();
    
    style = $.extend({backgroundColor:'#F0F0F0'},style);
    return (
        <div className={className+ " p-1"} style={style}>
            <div style={{backgroundColor:'#E0E0E0'}}>
                <h4 style={{display:"inline-block"}}>Filtering</h4>
                <CheckBox
                    className=" mx-2 " 
                    label= "Excl Partials"
                    value={!!filter.exclNominalSessions}
                    onChange={e=>exclNominalChanged(e.target.value)}
                    style={{display:'inline-block'}}  
                />
                <ExOptionSelector 
                    className=" mx-2 " 
                    style={{display:'inline-block'}}  
                    options = {filterOptions} 
                    value={filter.mode}  
                    onChange={changeFilterMode} 
                />
                
                <OptionSelector 
                    style = {{float:'right'}}
                    referers = {offerAnyCampaignInfoFiltering?['referers','pages','campaigns','campaign info']:['referers','pages','campaigns']} 
                    value={getFilterFields()}  
                    onChange={filterFieldsChanged}  
                />
            </div>
            <div  className=" p-2 my-2" >
                {managementButton}
                {activeFilter}
            </div>
            
        </div>
    )
}

export  function FilterPair(props){

    var {className,style,referers,campaigns,value,onChange,onDelete} = props;

    var [getValue,setValue] = useProperty(value);

    var allReferers = ['Any','None',...referers];
    var allCampaigns = ['Any','None',...campaigns];
 

    function updateValueWithReferer(referer){
        var pair = getChosenPair();
        pair[0] = referer;
        setValue(pair.join(','));
        onChange(pair);
    }

    function updateValueWithCampaign(campaign){
        var pair = getChosenPair();
        pair[1] = campaign;
        setValue(pair.join(','));
        onChange(pair);
    }

    function getChosenPair(){
        var value = getValue();
        value = value?value:'Any,Any';
        var [pair] =  splitCommaSeparatedList(value); 
        return pair;    
    } 

    var pair = getChosenPair();

    var refererItems = [];
    var campaignItems = [];

    $.each(allReferers,(r,referer)=>{
        refererItems.push(
            <MDBDropdownItem link  key={r} onClick={()=>{updateValueWithReferer(referer)}}  >{referer}</MDBDropdownItem>
        );
    });

    $.each(allCampaigns,(c,campaign)=>{
        campaignItems.push(
            <MDBDropdownItem link  key={c} onClick={()=>{updateValueWithCampaign(campaign)} }>{campaign}</MDBDropdownItem>
        );
    });

    return  <div className={className+" "+" hstack "} style={style}>
        <div className=" m-2" key="0">
            <MDBDropdown>
                <MDBDropdownToggle  color="success" >{pair[0]}</MDBDropdownToggle>
                <MDBDropdownMenu >
                    {refererItems}
                </MDBDropdownMenu>
            </MDBDropdown>
        </div>
        <div 
            className="bg-light "
            style= {{width:'3em'}} 
            key = "1"
        >
            AND
        </div>
        <div className=" m-2" key="2" >
            <MDBDropdown>
                <MDBDropdownToggle  color="success" >{pair[1]}</MDBDropdownToggle>
                <MDBDropdownMenu >
                    {campaignItems}
                </MDBDropdownMenu>
            </MDBDropdown>
        </div>
        <MDBBtn style={{textTransform: 'lowercase'}} className="small p-1 ms-4  border" color="light" onClick={onDelete}>Remove line </MDBBtn>
    </div>

}
function stringifyFilter(filter){
    var pairValues=[];
    $.each(filter,(p,pair)=>{
        pairValues.push( pair.join(','));
    });

    return pairValues.join(':');
}

export function AdvancedFilterEditor(props){

    var {value,referers,campaigns,onChange} = props;

    var [getValue,setValue] = useParameter(value);

    function getFilter(){
        var filterString = getValue();
        return parseAdvancedFilter(filterString);
    }

    var filter = getFilter();

    function updateValueWithPair(index,pair){
        var f = getFilter();
        f[index]=pair;
        setValue(stringifyFilter(f));
        onChange(getValue());
    }

    function deletePair(index){
        var f = getFilter();
        f.splice(index,1);
        setValue(stringifyFilter(f));
        onChange(getValue());
    }



    function addPair(){
        var f = getFilter();
        f.push(['Any','Any']);
        setValue(stringifyFilter(f));
        onChange(getValue());
    }

    var filters=[];

    $.each(filter,(p,pair)=>{
        filters.push(
            <FilterPair value = {pair.join(',')} className="" link referers={referers} campaigns={campaigns} onChange={pair=>{updateValueWithPair(p,pair)}} onDelete={()=>deletePair(p)}  key={p}></FilterPair>
        );
    });

    function showHelp(){
        showDialog({
            title :'Advanced Filters',
            message:[
                " A line is Referrer and a Campaign.",
                "A session matches a line if both the referrer AND the campaign match",
                "Sessions are included in the table if they match ANY of the 'lines'."
            ],
            ok : 'close'
        })
    }
    return  (
        <div className="vstack p-2" style={{position:'relative'}}>
            <div style={{display:(filters.length)?'none':'block'}}>
                <p>Add a line to build the filter.</p>
            </div>
            {filters}
            <MDBBtn className="my-2" style={{width:'10em' }} onClick={addPair} key='1 '>Add Line</MDBBtn>
            <MDBBtn style={{width:'5em' ,  textTransform: 'lowercase' , position:'absolute' , right:'0em',top:'0em' }} className="small p-1 ms-2  border" color="light" onClick={showHelp} key="2" >help </MDBBtn>

        </div>
    );

}

function parseAdvancedFilter(value){

    var filter = [];
    var [pairs] = splitCommaSeparatedList(value,':');

    $.each(pairs,(p,pairValue)=>{

        var [pair] = splitCommaSeparatedList(pairValue);
        filter.push(pair);
    });

    return filter;
}