
import React, { Component , useEffect,useRef,useState } from 'react';
import $, {  } from 'jquery';
import { Button } from 'react-bootstrap';

export function filterProps(props,filter){

  const remainderProps = $.extend({},props);
  const result = {};
  filter.map(prop=>{
    result[prop]= props[prop];   
    delete remainderProps[prop];
  });

  result.forwardProps = remainderProps;
  return  result
}

export function useMount(onMount){
    useState (onMount);
}

export function useEventSource(){

  const [event,setEventState] = useState('unfired');

  return [
    event,
    function fire(eventData){ // called to trigger the event in all listeners
      setEventState({data:eventData}) // wraps data so that state changes even if the data does not
    }
  ];
}

export function useEvent(eventState,onFired){
  
  const event = useRef(eventState);
  if (event.current != eventState){
    event.current = eventState;
    if (event!='unfired'){
      onFired(event.current.data)
    }
  }
}

export function useParameter (prop,onSet) {

  const original = useRef(prop);
  const [dummy,setValue] = useState(prop);
  const ref = useRef(dummy);

  // a change in 'prop' is written to state overriding any other state set by calling setState
  if (prop!= original.current){
    original.current = prop;
    doSet(prop);
  }

  function doSet(value){
    setValue(value);
    ref.current = value;
    if (onSet){
      onSet(value)
    }
  }

  return [
      function get(isControlled){

          /*
            if isControlled is set and value is defined then always returns that value
            when the state is initialized from a property this allows the property to override the state
          */
    
          if (!!isControlled && isDef(prop)){
            return prop
          } else{
            return ref.current;
          }
      },
      function set(value){
          doSet(value);
      },
      function onChange(e){
        doSet(e.target.value);
      }
    ];
}

export function useCookie(cookie,defaultValue) {

  var initialized = useRef(false);

  if (!initialized.current){
    var fromCookie = getCookie(cookie);
    if ( fromCookie === null) {
      setCookie(cookie,defaultValue);
    }
  }
  
  const [dummy,setValue] = useState(getCookie(cookie));
  const ref = useRef(dummy);

  return [
      function get(){
          return ref.current;
      },
      function set(value){
          setCookie(cookie,value);
          setValue(value);
          ref.current = value;
      }
    ];
}

export function useProperty (value,onSet) {
  
  const [dummy,setValue] = useState(value);
  const ref = useRef(dummy);

  function doSet(value){
    setValue(value);
    ref.current = value;
    if (onSet){
      onSet(value)
    }
  }

  return [
      function get(isControlled){

        /*
          if isControlled is set and value is defined then always returns that value
          when the state is initialized from a property this allows the property to override the state
        */
    
          if (!!isControlled && isDef(value)){
            return value
          } else{
            return ref.current;
          }
      },
      function set(value){
          // console.debug("setState : "+ value?value.toString():'null')
          doSet(value);
      },
      function onChange(e){
        doSet(e.target.value);
      }
    ];
}

// export function useProperty (initial) {

//   const [value,setValue] = useState(initial);
//   const ref = useRef(initial);

//   return [
//       function get(){
//           return ref.current;
//       },
//       function set(value){
//           setValue(value);
//           ref.current = value;
//       }
//     ];
// }

export class UniqueStrings{
  ordered = [];
  byName = {};

  add = function(value){
    if(!this.byName[value]){
      this.byName[value] = value;
      this.ordered.push(value);
    }
  };
}
export function CheckBox ({value,passive,label,onChange,keyId,size,className,style}){

  const [getChecked,setChecked] = useParameter(value);

  //size = size?size:'2em';

  function clicked(e){
      if (!passive){
        setChecked(!getChecked(true));
      }
      if (onChange){
        onChange({target:{value:!getChecked(true)}});
      }
  }

  var squareSize = size?{
    width:size,
    height:size
  }:{};
  const wholeStyle = $.extend({
    padding:'10px',paddingTop:'5px',paddingBottom:'5px',cursor:'pointer',
    color:getChecked()?'#FFFFFF':'#000000',
    backgroundColor:getChecked()?'#101030':'#FFFFFF',
},style,squareSize);

  return <div 
      style={wholeStyle} key={keyId}         
      onClick = {clicked}
      className = {"border rounded-5 "+className}    
  >
    {label?label:''}
  </div>
  
}

export function ToggleButton({value,text,size,keyId,onChange,className,style}){

  const [getChecked,setChecked] = useParameter(value);

  className = className?className:"";
  style = $.extend({},{
    textTransform: 'none',
    backgroundColor : (getChecked()?'':'white')
  },style);
  // console.log ("checked '"+ text + "' "+ getChecked());
  return <Button
      className = {className}
      style = {style}
      size= {size}
      key = {keyId}
      onClick = { () => { 
          const result = !getChecked();
          if ( onChange ) {
            onChange({target:{value:result}})
            if ( isUndef(value) ) {
              setChecked(result);
            }
          } else {
            setChecked(result);
          }
      }}
      variant = {getChecked()?"primary":"outline-primary"}
  >
    {text}
  </Button>

}

export function objLength(obj){
  return Object.keys(obj).length
}
export function dateFromMysqlDate(mysqlDate){

  // Split timestamp into [ Y, M, D, h, m, s ]
  var t = mysqlDate.split(/[- :]/);

  // Apply each element to the Date function
  return new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
}

export function isPageVisible(){
  let hidden;
  let visibilityChange;
  if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }
  return !document[hidden];
}
export function elipsise(value,maxLength) {
  if (value.length > maxLength) {
     return value.substring(0, maxLength) + '...';
  }
  return value;
};

export function format1dp(n){
    return (Math.ceil(n*10)/10).toString();
}
export function format2dp(n){
  return (Math.ceil(n*100)/100).toString();
}
export function format3dp(n){
  return (Math.ceil(n*1000)/1000).toString();
}
export function formatPcnt2dp(n){
  return format2dp(n*100) + '%';
}
export function formatPcnt(n){
  return Math.ceil(n*100).toString() + '%';
}

export function dateToMysqlString (d){
  return d.toJSON().slice(0, 19).replace('T', ' ');
}

export  function formatTime(hours,minutes){
  return (hours%12).toString().padStart(2,"0") + ' : ' + minutes.toString().padStart(2,"0") + ((hours>11)?' pm':' am');
}

export function stackProps(stack,prop){
  const props = [];

  $.each(stack,itemKey=>{
    const item = stack[itemKey];
    props.push(item[prop]);
  });

  return props;
}
export function doesArrayContain(arr,value){
    var result = false;
    $.each(arr,(k)=>{
      if ( arr[k] == value ){
        result = true;
        return false;// breaks out of the each loop
      }
    });
    return result;
}

export function filterStack (stack,stackProp,equ){

  if (!stack){
    return [];
  }

  const filter = isArray(equ)?(
    function (item) {
      return equ?doesArrayContain(equ,item[stackProp]):false;
    }
  ):(
    function (item) {
      return item?item[stackProp]==equ:false;
    }
  );

  return $.grep(isArray(stack)?stack:(Object.values(stack)),filter);

}

export function arrayToNamed(a){
    var byName={};
    $.each(a,(i,v)=>{
      byName[v]=true;
    });
    return byName;
}
export function splitCommaSeparatedList(csl,separator){

  csl = csl?csl.trim():'';

  var byName = {};
  var list = [];
  var a = csl?csl.split(separator?separator:','):[];

  $.each(a,(i,v)=>{
    list.push(v);
    byName[v]=true;
  });
  
  return [list,byName];
}

export function arrayToCommaSeparatedList(a){
  var csl = '';
  $.each(a,(v,value)=>{
    csl = csl?csl+",":csl;
    csl += value;
  })
  return csl;
}

export function dereferenceStackKey(stack,key,foreignStack,foreignId){
  const newStack = [];

  const finder = isDef(foreignId)?(
      link => {
        var result = undefined;
        $.each(foreignStack,itemKey=>{
          const item = foreignStack[itemKey];
          if (item[foreignId]==link){
            result = item;
            return false;
          }
        });
        return result;
      }
    ):(
      link => {
          return  foreignStack[link];
      }
  );

  $.each(stack,itemKey=>{
    const item = stack[itemKey];
    const newItem = $.extend({},item);
    newItem[key] = finder(item[key]);
    newStack.push( newItem);      
  });

  return newStack;
}

export function getCookie(cookieName){
  const cookies = document.cookie.split(';');
  for (var i =0;i< cookies.length;i++){
    var cookie = cookies[i].split("=");
    if (cookie[0].trim()==cookieName){
      return cookie[1].trim();
    }
  }
  return null;
}




export function log(msg){ // DEVELOPMENT shouldn't do anything
  if (window.console && console.log) {
    console.log(msg); //for firebug
  }
  //document.write(msg); //write to screen
  //$("#logBox").append(msg); //log to container
}

export function setCookie(name,value){
  document.cookie = `${name}=${value}; expires=Thu, 18 Dec 3000 12:00:00 UTC`;
}

export function deleteCookie(cookieName){
  document.cookie = cookieName+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function getQueryParams(){
  
  const params = {};

  const url = window.location.href;
  const qmIndex = url.indexOf("?");

  if (qmIndex>=0 && url.length>qmIndex){
    const paramStr = url.substring(qmIndex+1);
    if (paramStr.length>2){
      const paramPairStrs = paramStr.split("&");      
      $.map(paramPairStrs,(pair)=>{
        if (pair.length>2){
          const equal = pair.split("=");
          if (equal.length==2){
            params[equal[0]]=equal[1];
          }
        }
      });
    }

  }

  return params;

  // const params = new Proxy(new URLSearchParams(window.location.search), {
  //   get: (searchParams, prop) => searchParams.get(prop),
  // });


}

export function split (s,separator=','){
  if (!s){
    return [];
  }
  const a = s.split(separator);
  return (a.length==1 && a[0]=='')?[]:a;
}
export class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, stackTrace) {
      this.setState({ hasError: true  });
      console.log(error, stackTrace);
      // Send error to backend
      //Data.trackError(error, stackTrace.componentStack, this.props.component, this.props.location, this.props.guestLandingPage?.identifier_token, this.props.user?.id);
    }
  
    render() {
      if (this.state.hasError) {
        return (<div>Error</div>);
      }
  
      return this.props.children; 
    }
}


export function replaceAt(str,index,replacement){
  return str.substring(0, index) + replacement + str.substring(index + replacement.length);
}
export function isDef(value){
  return typeof value !='undefined';
}
export function isBool(value){
  return typeof value =='boolean';
}
export function isString(value){
  return typeof value =='string';
}
export function isUndef(value){
  const result = typeof (value)=='undefined';
  return result;
}
export function isArray(value){
  return Array.isArray(value);
}
export function isNumber(value){
  return typeof value =='number';
}

// export function sendCommand(args){

//   args = ensureCmdArgs(args);

//   const { actions, handler , updatesToMerge } = args;

//   const jason_commands = JSON.stringify({
//     actions : actions
//   });
//   const $updateTablesToMerge = 
  
//   // Send a POST request
//   axios ( {
//     method: 'post',
//     url: 'http://localhost:8080/msdata/command.php',
//     data: jason_commands
//   } ).then (
//     response => {
//       // wholesale change
//       const modelUpdates = response.data.modelUpdates;
//       if ( modelUpdates ) {
//           store.dispatch({
//               type : 'modelUpdate',
//               modelUpdates : modelUpdates,
//               updatesToMerge : updatesToMerge  // tables to be updated rather than overwritten 
//           });
//       }
//       if ( handler) {
//           handler(response);
//       }
//     }
//   );

// }



  export function compareArrayIds (a,b) {

    if (a.length != b.length) {
        return false;
    }

    for ( var i = 0;i<a.length; i++){
        if (a[i].id !=b[i].id){
            return false;
        }
    }
    return true;
}



export function compareArrayOfNumbers (a,b) {

  if (a.length != b.length) {
      return false;
  }

  for (var i = 0;i<a.length; i++){
      if (a[i] !=b[i]){
          return false;
      }
  }
  return true;
}

export function table_GetMemberProperty(table,key,field){
  if (table){
    const piece = table[key];
    return piece?piece[field]:undefined;
  } else {
    return undefined;
  }
}
export function table_GetSortedChildrenIds(table,foreignKey,parentId,idField){
    // piece[foreignKey] == parentId
    // foreignKey : the field in table which is being filtered by
    // parentId : the value that tghe foreign key is being compared against
    // idField the field which is being returned
    idField = (idField)?idField:"id";
    const ids = [];
    if (table){
        $.map (table,(piece)=>{ 
          if (piece[foreignKey] == parentId){ 
            ids.push(piece[idField]); 
          }
        });
    } else {
      return [];
    }
    ids.sort(); // don't care that the order is wrong as long as it is consistent
    return  ids;
}

function table_filter(table,a,b){
  if (table) {
    var condition = a;
    if (typeof condition !== 'function'){    
        condition = function(piece){return piece[a]==b}
    } 
    return $.grep(Object.values(table),condition);
  } else {
    return [];
  }
}

export function checkAll(checks){

  var error=null;
  for (var i in checks){
    error = check(checks[i]);
    if (error){
      break;
    }
  }
  
  return error;
  
}
export function check (args){
  
  var error=null;

  if (isArray(args)){
    if (isString(args[0])) {

      const fieldName = args[0];
      const value = args[1];
      
      for (var i = 2;i<args.length;i++){
        error = args[i](value);
        if (error){
          error = fieldName+" " +error
          break;
        }
      }
      
    } else {
      for (var i = 0;i<args.length;i++){
        error = args[i]();
        if (error){
          break;
        }
      }
    }
  } else {
    error = args();
  }
  return error;
}

export function checkMatch(value) {
  return (a)=>{
    return (a==value)?null:'do not match.';
  }
}

export function checkMin(minSize){
  return (a)=>{
    if (!a || (a.length <= minSize)) {
      return 'must be at least '+minSize+' letters long';
    } else {
      return null;
    }
  }
}

export function checkMax(maxSize){
  return (a)=>{
    if (a) {
      if (a.length >= maxSize) {
        return 'can only be '+ (maxSize)+' letters long or shorter.';
      } else {
        return null;
      }
    }
  }
}

export function checkAlowedLetters_label(){
  return (a)=>{
    
    if (!a || !(String(a).match(
      /^[" "0-9A-Za-z\s\-]+$/
    ))) {
      return 'can only contain letters numbers and spaces.'
    }
    
  }
}

export function checkAlowedLetters_password(){
  return (a)=>{
    if (!a || !(String(a).match(
      /^[a-zA-Z0-9!@#$%^&*]+$/
    ))) {
      return 'can only contain letters numbers and any of these @#$%^&*';
    }
    
  }
}
export function checkEmailAddress(){
  return (email)=>{

    if (!(String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    )){
      return 'is not formatted correctly.'
    }
    
  }
}