
import { shallowEqual, useSelector } from "react-redux";
import { format2dp, formatPcnt, formatPcnt2dp, getCookie, getQueryParams, setCookie, splitCommaSeparatedList, useCookie, useProperty } from "./general";
import { sendCommand } from "./Command";
import {  useEffect, useRef,useState ,useContext } from "react";
import {  addReportRow, formatAvgWithStability, makeEventReport, makeReportHeader } from "./events";
import { ColumnSelector } from "./ColumnSelector";
import { MDBBtn, MDBCheckbox, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";
import { showDialog } from "./ModalDialog";
import { UxRaw } from "./us_raw";
import $ from "jquery";
import { FilterPair , FilterEditor, doesSessionPassCompFilter, CompFilterEditor, makeEmptyFilter, parseCompFilterString, getCompositeFilter} from "./FilterPair";
import { ExclOptionSelector } from "./OptionSelector";

function median(values) {

    values = [...values].sort((a, b) => a - b);
  
    const half = Math.floor(values.length / 2);
  
    return (values.length % 2
      ? values[half]
      : (values[half - 1] + values[half]) / 2
    );
  
  }



export function UxPageViewsReport(props){

    var {accountId,period} = props;

    const firstTime = useRef(true);
    
    const uxPageRepCols = getCookie('uxPageRepCols');
    const [getChosenColumns,setChosenColumns] = useProperty(uxPageRepCols)

    const [getPart,setPart] = useCookie('PagePart','All');

    const filterCookie = 'uxCFilter';
    var filterString = getCookie(filterCookie);
    var emptyFilter = makeEmptyFilter();
    filterString = filterString?filterString:(JSON.stringify(emptyFilter));
    var [getFilter,setFilter] = useProperty(filterString)


    var columns = [
        "Page",
        "Views",

        "Tot Duration (s)",
        "Av Duration (s)",
        // "Mdn Duration (s)",
        "Prop Duration",
        
        "Tot Engagement",
        "Av Engagement",
        // "Mdn Engagement",
        "Prop Engagement"
    ]

    // function getCompositeFilter(){
    //     return parseCompFilterString(getFilter());
    // }

    var events = useSelector(state => {
        var model = (state.model)?state.model:{};
        return model.events?model.events:[];
    },shallowEqual);

    var rows =[];
    var referers = [];
    var campaigns = [];
    
    var csvRef = {csv};
    var headerRow = makeReportHeader(columns,getChosenColumns(),csvRef);
    var csv = csvRef.csv;

    if (events){
        var results = makeEventReport(events,period);
        
        referers = results.orderedReferers;

        campaigns = results.orderedCampaigns;

        campaigns.push('None');

        var compositeFilter = getCompositeFilter(getFilter(),results);


        function makeReport(baseReport,key){
            var report = {
                key : key,
                count : 0,
                duration : 0,
                durations : [],
                engagement : 0,
                engagements : [],
                pageViews : [],

                subReportsByKey : {},
                orderedSubReports : [],

                sortToList : function (targetList,depth){
                    depth = depth ? depth : 0;

                    targetList.push(this)
                    
                    if (
                        (getPart()=='All') || (depth < 1)
                        // ((getPart()=='Just Campaigns') && (depth <= 0)) ||
                        // ((getPart()=='With AdGroups') && (depth <= 1))                         
                    ){

                        this.orderedSubReports.sort((a,b)=>{return b.count - a.count});
                        for (var r = 0 ; r < this.orderedSubReports.length ; r++) {
                            this.orderedSubReports[r].sortToList(targetList,depth+1);
                        }

                    }
                }
                
            }
            if (baseReport){
                baseReport.orderedSubReports.push(report);
                baseReport.subReportsByKey[key]=report;
            }
            return report;
        }

        function applyPageToReport(page,report){

            report.pageViews.push(page);
            report.count ++;
            report.duration += page.activeDuration;
            report.durations.push(page.activeDuration);
            report.engagement += page.numEngagement;
            report.engagements.push(page.numEngagement);
        }

        var baseReport = makeReport(null,'All Pages');

        for (var p=0;p < results.orderedPages.length;p++){
            (function(page){


                if (doesSessionPassCompFilter(page.session,compositeFilter)) {

                    var keys = page.pageName.split('/');

                    var targetReport = baseReport;
                    applyPageToReport(page,targetReport);

                    var key="";
                    for (var k = 0 ; k < keys.length; k++){
                        key+='/';
                        // key += (key)?'/':'';
                         key += keys[k];

                        var keyName = key;
                        if (k<keys.length-1 && key!='All Pages'){
                            keyName += '/*';
                        }

                        var report = targetReport.subReportsByKey[keyName];
                        if ( !report ) {
                            report = makeReport(targetReport,keyName);
                        }
                        applyPageToReport(page,report);

                        targetReport = report;
                    }

                }
            })(results.orderedPages[p])
        }

        var reports = [];
        baseReport.sortToList(reports);

        for (var r = 0 ; r < reports.length ; r++) {
            var report = reports[r];
            csv += addReportRow(rows,[

                report.key,
                report.count,
                
                format2dp(report.duration/1000),
                //format2dp((report.duration/report.count)/1000),
                formatAvgWithStability(report,true,report.pageViews,pageView=>pageView.activeDuration/1000),
                // format2dp(median(report.durations)/1000),
                formatPcnt(  report.duration / baseReport.duration),

                report.engagement,
                //format2dp(report.engagement/report.count),
                formatAvgWithStability(report,true,report.pageViews,pageView=>pageView.numEngagement),
                // median(report.engagements),
                formatPcnt(  report.engagement / baseReport.engagement),
            
            ],columns,getChosenColumns());

        }
        
    }

    
    function chosenColumnsChanged(value){
        setCookie('uxPageRepCols',value);
        setChosenColumns(value);
    }

    function copyCSV(){
        navigator.clipboard.writeText(csv);
        showDialog({
            message:"Data copied to clipboard as csv",
            ok : 'Close'
        });
    }

    function onKeyFilterChanged (value){
        setPart(value)
    }

    function onFilterChanged(filterString){
        setCookie(filterCookie,filterString);
        setFilter(filterString)
    }

    return (<div>

        <div className="p-1 mb-2" style = {{backgroundColor:'#F0F0F0'}}>
            <div style = {{backgroundColor:'#E0E0E0'}} >
                <h3>Columns</h3>
            </div>
            <ColumnSelector className="border my-2"  columns={columns} value={getChosenColumns()} onChange={chosenColumnsChanged} ></ColumnSelector>
        </div>


        <CompFilterEditor  value={getFilter()}  report = {results}  onChange={onFilterChanged} ></CompFilterEditor>

        <MDBBtn style={{width:'10em'}} onClick={copyCSV} className="my-2">Copy CSV</MDBBtn>
        <h2>Page Report</h2>
        <p>Values in grey gives the probable error range ( % +/- ) of the preceding summarizing value. As the sample size goes up the error range should go down.</p>
        <ExclOptionSelector
            values = {['Just Summaries','All']}
            onChange = {onKeyFilterChanged}
            value = {getPart()}
        ></ExclOptionSelector>
        <table className="border">
            <thead>
                {headerRow}
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
        <br/>
        <h3>What this table tells you.</h3>
        <p>This table shows information about all the page views in the sessions you have selected (using the Period and Filtering panels at the top.)</p>
        <p>Information is given for each page individually and for groups of pages such as all collection pages or all product pages.</p>
        <p>Here is what each column in the table means.</p>
        <ul>
            <li>Page - The path of the page or group of pages. A path that ends in '/*' is group of pages  otherwise it is a single page.The row headed 'All Pages' is summary of all the pages viewed.</li>
            <li>Views - The total number of times that page or group of pages was viewed.</li>
            <li>Tot Duration - The total number of seconds spent viewing the page or group of pages.</li>
            <li>Av Duration - The average number of seconds spent viewing the page or group of pages.</li>
            <li>Mdn Duration - The median number of seconds spent viewing the page or group of pages.</li>
            <li>Prop Duration - The total number of seconds spent viewing the page or group of pages expressed as a percentage of the total time spent viewing all the pages.</li>
            <li>Tot Engagement - The total engagement with the page or group of pages.</li>
            <li>Av Engagement - The average engagement with the page or group of pages.</li>
            <li>Mdn Engagement - The median engagement with the page or group of pages.</li>
            <li>Prop Engagement - The total engagement with the page or group of pages expressed as a percentage of the total total engagement with all the pages.</li>
        </ul>
        


    </div>)
}