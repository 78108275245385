
import { shallowEqual, useSelector } from "react-redux";
import { getCookie, getQueryParams, setCookie, splitCommaSeparatedList, useProperty } from "./general";
import { sendCommand } from "./Command";
import {  useEffect, useRef,useState ,useContext } from "react";
import {  addReportRow, formatAvgWithStability, formatPcntWithStability, makeEventReport, makeReportHeader } from "./events";
import { ColumnSelector } from "./ColumnSelector";
import { MDBBtn, MDBCheckbox, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";
import { showDialog } from "./ModalDialog";
import { UxRaw } from "./us_raw";
import $ from "jquery";
import { ExclusiveSelector } from "./ExlusiveSelector";
import { FilterPair , FilterEditor, doesSessionPassCompFilter, CompFilterEditor, makeEmptyFilter, parseCompFilterString, getCompositeFilter} from "./FilterPair";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryStack, VictoryTheme } from "victory";
// import { Distribution }  from "./distributionChart_Victory";
import { Distribution } from "./distributionChart";

export function Dummy(){

}

export function UxSessions(props){

    var {accountId,period} = props;

    const firstTime = useRef(true);
    
    const uxSessionsCols = getCookie('uxSessionsCols');
    const [getChosenColumns,setChosenColumns] = useProperty(uxSessionsCols)

    const filterCookie = 'uxCFilter';
    var filterString = getCookie(filterCookie);
    var emptyFilter = makeEmptyFilter();
    filterString = filterString?filterString:(JSON.stringify(emptyFilter));
    var [getFilter,setFilter] = useProperty(filterString)


    var events = useSelector(state => {
        var model = (state.model)?state.model:{};
        return model.events?model.events:[];
    },shallowEqual);

    
    var chartValues = [];
    var chartDurationValues = [];
    var chartName = "Engagements";

    
    var columns = [
        "Date",
        "Time",
        "User Id",
        "Length (s)",
        "Tot Pages",
        ["Pages",'15em'],
        "Referrer",
        ["Landing Page",'15em'],
        "Engagement",
        "Campaign",
        "AB",
        "Answers",
        // "addClickId",
        "adGroup",
        "Search Term",
        "raw",
    ]

    var rows =[];
    var referers = [];
    var campaigns = [];

    
    var csvRef = {csv};
    var headerRow = makeReportHeader(columns,getChosenColumns(),csvRef);
    var csv = csvRef.csv;

    var avgPages ;
    var avgDuration;
    var avgEngagement;
    var pcntBounced;

    if (events){
        var results = makeEventReport(events,period);
        
        referers = results.orderedReferers;
        campaigns = results.orderedCampaigns;

        campaigns.push('None');

        var compositeFilter = getCompositeFilter(getFilter(),results);

        var sessions = [];

        // clean chosen
        for (var s=0;s < results.orderedSessions.length;s++){
            (function(session){

                if (doesSessionPassCompFilter(session,compositeFilter)) {


                    sessions.push(session);

                    // if (!eo || session.engaged){
                        var pages = "";
                        var addedByName ={};
                        for (var p = 0;p<session.orderedPages.length;p++){
                            var page = session.orderedPages[p];
                            if (!addedByName[page.pageName]){
                                addedByName[page.pageName] = page;
                                pages = pages?(pages+": "):"";
                                pages += page.pageName;
                            }
                        }
                        var dt =  new Date(session.sessionEvent.timestamp);
                        csv += addReportRow(rows,[
                            dt.toDateString(),
                            dt.getHours().toString().padStart(2,'0')+":"+dt.getMinutes().toString().padStart(2,'0'),
                            session.userId,
                            Math.ceil((session.sessionLast-session.sessionStart)/1000),
                            session.numPages,
                            pages,
                            session.sessionEvent.sessionReferrer,
                            session.page,
                            session.numEngagement,
                            session.sessionEvent.sessionCampaign,
                            session.sessionEvent.splitVersion,
                            session.questionsAnswered?session.questionsAnswered:'',
                            // session.addClickId,
                            session.sessionSubCampaign,
                            session.sessionSearch,
                            <a style={{color:'red',cursor:'pointer'}} onClick={()=>orRawClick(session)}>raw</a>
                        ],columns,getChosenColumns());



                    // }

                    chartValues.push(session.numEngagement?session.numEngagement:0);
                    
                    chartDurationValues.push(session.sessionDuration?session.sessionDuration/1000:0);
                }
            })(results.orderedSessions[s])

        }

        avgPages = formatAvgWithStability(results,true,sessions,session=>{
             return session.orderedPages.length;
        });

        avgDuration = formatAvgWithStability(results,true,sessions,session=>{return session.sessionDuration/1000;});

        avgEngagement = formatAvgWithStability(results,true,sessions,'numEngagement');

        pcntBounced = formatPcntWithStability(results,true,sessions,session=>{return session.orderedPages.length == 1;});

        

    }

    function cleanMembers(a,valid){
        var result=[];
        $.each(a,(i,value)=>{
            if (valid[value]){
                result.push(value);
            }
        })
        return result;
    }
    
    function orRawClick(session){
        
        showDialog({
            title : 'Raw Session',
            message :  {
                tag : UxRaw,
                props : {style:{width:'100%'} ,accountId:accountId, sessionId : session.sessionId} ,
            },
            ok : 'Close'
        })
        var ii=0;
    }

    function chosenColumnsChanged(value){
        setCookie('uxSessionsCols',value);
        setChosenColumns(value);
    }



    function copyCSV(){
        navigator.clipboard.writeText(csv);
        showDialog({
            message:"Data copied to clipboard as csv",
            ok : 'Close'
        });
    }


    function onFilterChanged(filterString){
        setCookie(filterCookie,filterString);
        setFilter(filterString)
    }


      
      

    return (<div>


        <div className="p-1 mb-2" style = {{backgroundColor:'#F0F0F0'}}>
            <div style = {{backgroundColor:'#E0E0E0'}} >
                <h3>Columns</h3>
            </div>
            <ColumnSelector className="border my-2"  columns={columns} value={getChosenColumns()} onChange={chosenColumnsChanged} ></ColumnSelector>
        </div>

        <CompFilterEditor  value={getFilter()} 
            report = {results}

            onChange = {onFilterChanged}
        ></CompFilterEditor>

        <MDBBtn style={{width:'10em'}} onClick={copyCSV} className="my-2">Copy CSV</MDBBtn>
        <h2>Sessions ( {rows.length} )</h2>

        {/* <table className="">
            <tbody>
                <tr><td>Average pages per session</td><td>{avgPages}</td></tr>
                <tr><td>Average session length (s).</td><td>{avgDuration}</td></tr>
                <tr><td>Average session engagement.</td><td>{avgEngagement}</td></tr>
                <tr><td>Percent of sessions bounced.</td><td>{pcntBounced}</td></tr>
            </tbody>
        </table> */}
        <br/>
        <table className="border">
            <thead>
                {headerRow}
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>

        
    </div>)
}
