import { MDBCheckbox } from "mdb-react-ui-kit";
import { getCookie, isString, setCookie, useProperty } from "./general";
import $ from "jquery";


export  function ColumnSelector(props){

    var {style,className,columns,value,onChange} = props;

    var colNames = [];
    $(columns).each((i,column)=>{
        if (isString(column)){
            colNames.push(column);
        } else {
            colNames.push(column[0]);
        }
    });

   value =  value?value:''

    var [getValue,setValue] = useProperty(value);

    var chosen = getChosen();

    var checkboxes = [];
    for (var c =0;c<colNames.length;c++){
        (function(colName){
            checkboxes.push(
                <div  key = {colName} className='p-1 border m-1' style={{display:'inline-block'}} >
                <MDBCheckbox
                    
                    label= {colName}
                    checked={!!chosen[colName]}
                    onChange={()=>reviewValue(colName)}
                    
                />
                </div >
                
            );
    
        })(colNames[c])
    }

    function reviewValue(colName){
        var chosen = getChosen();
        if (chosen[colName]){
            delete(chosen[colName]); 
        } else {
            chosen[colName]=colName;
        }
        var newValue = [];
        for (var prop in chosen) {
            if (Object.prototype.hasOwnProperty.call(chosen, prop)) {
                newValue.push(prop);
            }
        }

        var value = newValue.join(',')
        setValue(value);
        if (onChange){
            onChange(value);
        }
    }

    function getChosen(){
        var chosenDictionary = {};
        var chosen = getValue();
        chosen= (chosen?chosen:"").split(',');
        if ( chosen.length==1 && !chosen[0]){
            chosen =[]
        }
        for (var i=0;i<chosen.length;i++){
            chosenDictionary[chosen[i]]=chosen[i];
        }
        return chosenDictionary;
    }

    return <div style={style} className={className} >
        {checkboxes}
    </div>

}