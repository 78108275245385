
import { shallowEqual, useSelector } from "react-redux";
import { getCookie, getQueryParams, setCookie, useProperty } from "./general";
import { sendCommand } from "./Command";
import {  useEffect, useRef,useState ,useContext } from "react";
import { addReportRow, makeEventReport, makeReportHeader } from "./events";
import { ColumnSelector } from "./ColumnSelector";
import $ from "jquery";
// import React, { useEffect, useRef,useState ,useContext} from 'react';

export function UxRaw(props,period){

    const {style,className,accountId,sessionId,pageViewId} = props;

    const firstTime = useRef(true);

    const uxRawCols = getCookie('uxRawCols');
    const [getChosenColumns,setChosenColumns] = useProperty(uxRawCols)

    var events = useSelector(state => {
        var model = (state.model)?state.model:{};
        return model.events?model.events:[];
    },shallowEqual);

    const eventRows = [];

    if (events) {

        makeEventReport(events,period);

        events.sort(function(a, b){return a.timestamp - b.timestamp});

        if (pageViewId){
            events = $.grep(events,event=>event.pageViewId==pageViewId);
        } else if (sessionId){
            events = $.grep(events,event=>event.sessionId==sessionId);
        }

        var columns = [
            "name",
            "timestamp",
            "at",
            "userId",
            "sessionId",
            "pageViewId",
            "pageName",
            "sessionReferrer",
            "sessionCampaign",
            "splitVersion",
            ["Data",'15em'],
        ]
    
        var headerRow = makeReportHeader(columns,getChosenColumns());

        for ( var  e=0 ; e<events.length ; e++ ) {
            (function(e){
                var event = events[e];
                addReportRow(eventRows,[
                    event.name,
                    event.timestamp,
                    event.sessionTime,
                    event.userId,
                    event.sessionId,
                    event.pageViewId,
                    event.pageName,
                    event.sessionReferrer,
                    event.sessionCampaign,
                    event.splitVersion,
                    event.data,
                ],columns,getChosenColumns());
            })(e)
        }
    }

    function chosenColumnsChanged(value){
        setCookie('uxRawCols',value);
        setChosenColumns(value);
    }

    return (<div className={className} style = {style}>
        <h1>Raw Events</h1>
        <ColumnSelector columns={columns} value={getChosenColumns()} onChange={chosenColumnsChanged} ></ColumnSelector>
        <table style={{margin:'0 auto'}}>
            <thead>
                {headerRow}
            </thead>
            <tbody>
                {eventRows}
            </tbody>
        </table>
    </div>)
}