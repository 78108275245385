
import { shallowEqual, useSelector } from "react-redux";
import { getCookie, getQueryParams, setCookie, useCookie, useParameter, useProperty } from "./general";
import { sendCommand } from "./Command";
import { UxRaw } from "./us_raw";
import { UxSessions } from "./ux_sessions";
import { UxPages } from "./ux_pages";
import { UxPageViewsReport } from "./ux_pageviews_report";
import { UxDays } from "./ux_days";
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBInput } from 'mdb-react-ui-kit';
import {  useEffect, useRef,useState ,useContext } from "react";
import { ExOptionSelector } from "./ExOptionSelector";
import { UxCampaignsReport } from "./ux_campaigns_report";
import { UxSessionsReporter } from "./ux_session_report.js";
import { Button } from "react-bootstrap";


// import React, { useEffect, useRef,useState ,useContext} from 'react';

export function UxMain(){

    const [getAccountId, setAccountId] = useCookie('accountId');
    const [report, setReport] = useState('Sessions');
    const [getPeriod, setPeriod] = useCookie('period','Yesterday');
    const refPeriodLoaded = useRef();
    const refAccountLoaded = useRef();
    const refRefreshCount = useRef(0);
    const [getRefreshCount, setRefreshCount] = useProperty(0);

    var reportInstance = null;

    var periodOptions = ['Today','Yesterday','Last 7 days','Last 30 Days','90 days','6 months','1 year'];

    var dayStart = new Date(new Date().toDateString()).getTime();

    var period = null;
    var periodType = getPeriod();

    switch(periodType){
        case 'Today' :{
            period = {
                from : dayStart,
                to : dayStart + (2*24*60*60*1000)
            }
        } break;
        case 'Yesterday' : {
            period = {
                from : dayStart - (24*60*60*1000),
                to : dayStart
            }
        }break;
        case 'Last 7 days' : {
            period = {
                from : dayStart - (6*24*60*60*1000),
                to : dayStart + (2*24*60*60*1000)
            }
        }break;
        case 'Last 30 Days' : {
            period = {
                from : dayStart - (29*24*60*60*1000),
                to : dayStart + (2*24*60*60*1000)
            }
        }break;
        case '90 days' : {
            period = {
                from : dayStart - (89*24*60*60*1000),
                to : dayStart + (2*24*60*60*1000)
            }
        }break;
        case '6 months' : {
            period = {
                from : dayStart - (183*24*60*60*1000),
                to : dayStart + (2*24*60*60*1000)
            }
        }break;
        case '1 year' : {
            period = {
                from : dayStart - (365*24*60*60*1000),
                to : dayStart + (2*24*60*60*1000)
            }
        }break;
    }

    if (refRefreshCount.current != getRefreshCount() ||  refAccountLoaded.current != getAccountId() ||  !refPeriodLoaded.current || refPeriodLoaded.current.to!=period.to || refPeriodLoaded.current.from != period.from) {
        
        refRefreshCount.current =  getRefreshCount();
        refPeriodLoaded.current = period;    
        refAccountLoaded.current = getAccountId();
        sendCommand({
            actions:[
                 {
                 objectType : 'Event', verb : 'readDateRange',
                 from : period.from,
                 to : period.to?period.to:(Date.now() + (24*60*60*1000)),
                 accountId : getAccountId()
             }
             ,
            {
                objectType : 'Campaign', verb : 'fetchAll',
                accountId : getAccountId()
            }
        ],
            modal : true
        });
    }
    
    switch (report){
        case 'Raw' :{
            reportInstance = <UxRaw accountId={getAccountId()} period={period}  ></UxRaw>
        }break;
        case 'Sessions' :{
            reportInstance = <UxSessions accountId={getAccountId()} period={period} ></UxSessions>
        }break;
        case 'Pages' :{
            reportInstance = <UxPages accountId={getAccountId()} period={period} ></UxPages>
        }break;
        case 'Page Report' :{
            reportInstance = <UxPageViewsReport accountId={getAccountId()} period={period} ></UxPageViewsReport>
        }break;
        case 'Days' :{
            reportInstance = <UxDays accountId={getAccountId()} period={period} ></UxDays>
        }break;
        case 'Campaign Report' :{
            reportInstance = <UxCampaignsReport accountId={getAccountId()} period={period} ></UxCampaignsReport>
        }break;
        case 'Sessions Report' :{
            reportInstance = <UxSessionsReporter accountId={getAccountId()} period={period} ></UxSessionsReporter>
        }break;
    }

    function refresh(){
        setRefreshCount( getRefreshCount()+1);
    }
    
    return (<div
        style ={{}}
        className="m-4"
    >
        <h1> Startup Analytics </h1>
        <div className="hstack gap-3 ">
            <DeferredInput 
                value ={getAccountId()}
                onChange={(value) => setAccountId(value)}
                style= {{maxWidth:'10em',width:'10em',display:'inline-block'}} 
                type='text' 
            />
            <div 
                className="bg-light m-0"
                style= {{width:'15em'}} 
            >
                Select a report
            </div>
            <Button className="" onClick = {refresh} style={{display:'inline-block'}}>Refresh</Button>
            <div className=" p-0 m-0" style={{display:'inline-block'}}>
                <MDBDropdown className=" p-1 m-0">
                    <MDBDropdownToggle>{report}</MDBDropdownToggle>
                    <MDBDropdownMenu >
                        <MDBDropdownItem link onClick={()=>{setReport('Raw')} }>Raw</MDBDropdownItem>
                        <MDBDropdownItem link onClick={()=>{setReport('Sessions')} }>Sessions</MDBDropdownItem>
                        <MDBDropdownItem link onClick={()=>{setReport('Pages')} }>Pages</MDBDropdownItem>
                        <MDBDropdownItem link onClick={()=>{setReport('Days')} }>Days</MDBDropdownItem>
                        <MDBDropdownItem link onClick={()=>{setReport('Sessions Report')} }>Sessions Report</MDBDropdownItem>
                        <MDBDropdownItem link onClick={()=>{setReport('Page Report')} }>Page Report</MDBDropdownItem>
                        <MDBDropdownItem link onClick={()=>{setReport('Campaign Report')} }>Campaign Report</MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
        </div>
        <div className="py-2">
            <div style={{backgroundColor:'#E0E0E0'}}  className="px-2">
                <h4 style={{display:"inline-block"}}>Period</h4>
                <ExOptionSelector
                    className=" mx-2 " 
                    style={{display:'inline-block'}}  
                    options = {periodOptions} 
                    value={getPeriod()}  
                    onChange={period=>setPeriod(period)} 
                >
                </ExOptionSelector>
            </div>
        </div>
        {reportInstance}
        <div style={{height:'10em'}}></div>
    </div>)
}


function DeferredInput({style,value,onChange}){

    const [getValue,setValue] = useParameter(value);
    const refInDelay = useRef();

    function inputChange(value){
        setValue(value);
        // if (refInDelay.current){
        //     clearTimeout(refInDelay.current);
        // }
        // refInDelay.current =setTimeout (()=>{onChange(value)},1000);
    }

    function ended(){
        onChange(getValue())
    }

    function onKey(e){
        if (e.key == 'Enter'){
            onChange(getValue())
        }
    }

    style = style?style:{};

    return <MDBInput 
        value ={getValue()}
        onChange={(e) => inputChange(e.target.value)}
        onBlur={ended}
        onKeyPress ={onKey}
        style= {style} 
        type='text' 
    />

}
