
import { shallowEqual, useSelector } from "react-redux";
import { getCookie, getQueryParams, setCookie, splitCommaSeparatedList, useProperty } from "./general";
import { sendCommand } from "./Command";
import {  useEffect, useRef,useState ,useContext } from "react";
import {  addReportRow, makeEventReport, makeReportHeader } from "./events";
import { ColumnSelector } from "./ColumnSelector";
import { MDBBtn, MDBCheckbox, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";
import { showDialog } from "./ModalDialog";
import { UxRaw } from "./us_raw";
import $ from "jquery";
import { FilterPair , FilterEditor, doesSessionPassCompFilter, CompFilterEditor, makeEmptyFilter, parseCompFilterString, getCompositeFilter} from "./FilterPair";

export function UxPages(props){

    var {accountId,period} = props;

    const firstTime = useRef(true);
    
    const uxPagesCols = getCookie('uxPagesCols');
    const [getChosenColumns,setChosenColumns] = useProperty(uxPagesCols)

    const filterCookie = 'uxCFilter';
    var filterString = getCookie(filterCookie);
    var emptyFilter = makeEmptyFilter();
    filterString = filterString?filterString:(JSON.stringify(emptyFilter));
    var [getFilter,setFilter] = useProperty(filterString)

    var events = useSelector(state => {
        var model = (state.model)?state.model:{};
        return model.events?model.events:[];
    },shallowEqual);

    var columns = [
        "UserId",
        "SessionId",
        "PageViewId",
        "Date",

        "Time",
        "Length (s)",

        "Name",
        "Engagement",
        "AB",
        "raw",
    ]

    var rows =[];
    var referers = [];
    var campaigns = [];
    
    var csvRef = {csv};
    var headerRow = makeReportHeader(columns,getChosenColumns(),csvRef);
    var csv = csvRef.csv;

    if (events){
        var results = makeEventReport(events,period);
        
        referers = results.orderedReferers;

        campaigns = results.orderedCampaigns;

        campaigns.push('None');

        var compositeFilter = getCompositeFilter(getFilter(),results);


        // clean chosen
        for (var p=0;p < results.orderedPages.length;p++){
            (function(page){

                if (doesSessionPassCompFilter(page.session,compositeFilter)) {

                    var dt =  new Date(page.pageStart);
                    csv += addReportRow(rows,[
                        page.session.userId,
                        page.session.sessionsId,
                        page.pageViewId,
                        dt.toDateString(),

                        dt.getHours().toString().padStart(2,'0')+":"+dt.getMinutes().toString().padStart(2,'0'),
                        Math.ceil(page.activeDuration/1000), 

                        page.pageName,
                        page.numEngagement,
                        page.session.sessionEvent.splitVersion,
                        <a style={{color:'red',cursor:'pointer'}} onClick={()=>orRawClick(page)}>raw</a>
                    ],columns,getChosenColumns());

                }
            })(results.orderedPages[p])

        }

    }


    function orRawClick(page){
        
        showDialog({
            title : 'Raw Session',
            message :  {
                tag : UxRaw,
                props : {style:{width:'100%'} ,accountId:accountId, sessionId : page.session.sessionId , pageViewId : page.pageViewId } ,
            },
            ok : 'Close'
        })
        var ii=0;
    }

    function chosenColumnsChanged(value){
        setCookie('uxPagesCols',value);
        setChosenColumns(value);
    }



    function copyCSV(){
        navigator.clipboard.writeText(csv);
        showDialog({
            message:"Data copied to clipboard as csv",
            ok : 'Close'
        });
    }


    function onFilterChanged(filterString){
        setCookie(filterCookie,filterString);
        setFilter(filterString)
    }

    return (<div>


        <div className="p-1 mb-2" style = {{backgroundColor:'#F0F0F0'}}>
            <div style = {{backgroundColor:'#E0E0E0'}} >
                <h3>Columns</h3>
            </div>
            <ColumnSelector className="border my-2"  columns={columns} value={getChosenColumns()} onChange={chosenColumnsChanged} ></ColumnSelector>
        </div>

        <CompFilterEditor  value={getFilter()}  report = {results}  onChange={onFilterChanged} ></CompFilterEditor>

        <MDBBtn style={{width:'10em'}} onClick={copyCSV} className="my-2">Copy CSV</MDBBtn>
        <h2>Page Views ( {rows.length} )</h2>
        <table className="border">
            <thead>
                {headerRow}
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    </div>)
}