import { MDBCheckbox } from "mdb-react-ui-kit";
import { CheckBox, arrayToNamed, getCookie, isString, setCookie, splitCommaSeparatedList, useParameter, useProperty } from "./general";
import $ from "jquery";


export  function ExOptionSelector(props){

    var {style,className,options,value,onChange} = props;

    var [getValue,setValue] = useParameter(value);

    console.log ('ExOptionSelector : value = '+ getValue() );

    var checkboxes = [];
    for (var c =0;c<options.length;c++){
        (function(option){
            checkboxes.push(
                <div  keyId = {option} className='p-1 border m-1' style={{display:'inline-block'}} >
                <CheckBox
                    label= {option}
                    value={getValue()==option}
                    onChange={()=>reviewValue(option)}
                    passive = 'true'
                />
                </div >
                
            );
    
        })(options[c])
    }

    function reviewValue(option) {

        setValue(option);
        if (onChange){
            onChange(option);
        }
    }

    return <div style={style} className={className}>
        {checkboxes}
    </div>

}