import './index.css';
import './App.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store  from './startanStore';
import reportWebVitals from './reportWebVitals';
import { FrontEnd } from './FrontEnd';
import {  } from './us_raw';
import { UxSessions } from './ux_sessions';
import { UxMain } from './ux_main';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <FrontEnd FrontFrame = {UxMain} />
    </Provider>
  </React.StrictMode>
);


reportWebVitals();
