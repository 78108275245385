
import { shallowEqual, useSelector } from "react-redux";
import { format2dp, getCookie, getQueryParams, setCookie, useProperty } from "./general";
import { sendCommand } from "./Command";
import {  useEffect, useRef,useState ,useContext } from "react";
import {  addReportRow, makeEventReport, makeReportHeader } from "./events";
import { MDBCheckbox } from "mdb-react-ui-kit";
import { CompFilterEditor, makeEmptyFilter, parseCompFilterString,doesSessionPassCompFilter, getCompositeFilter } from "./FilterPair";

export function UxDays(props){

    var  {period} = props;

    const filterCookie = 'uxCFilter';
    var filterString = getCookie(filterCookie);
    filterString = filterString?filterString:(JSON.stringify(makeEmptyFilter()));
    var [getFilter,setFilter] = useProperty(filterString)

    // function getCompositeFilter(){
    //     return parseCompFilterString(getFilter());
    // }

    const firstTime = useRef(true);

    var events = useSelector(state => {
        var model = (state.model)?state.model:{};
        return model.events?model.events:[];
    },shallowEqual);

    var referers = [];
    var campaigns = [];

    var rows =[];

    function makeReport(){
        return {
            rowTitle : '',
            users : 0,
            pages : 0,
            sessions : 0,
            engagement : 0,
            duration : 0,
            usersById : {},
            addUser : function(userId){
                if (!this.usersById[userId]){
                    this.usersById[userId] = true;
                    this.users +=1;
                }
            }
        }
    }

    var reports = [];
    if (events){
        var results = makeEventReport(events,period);
        
        referers = results.orderedReferers;
        campaigns = results.orderedCampaigns;

        var compositeFilter = getCompositeFilter(getFilter(),results);

        var summaryReport = makeReport();
        summaryReport.rowTitle = 'Totals';

        
        for (var d=0;d < results.orderedDays.length;d++){
            var day = results.orderedDays[d];
            var numSessions = 0;
            var numPages=0;
            var totalDuration = 0;
            var totalEngagement = 0;

            var validSessions = {};


            var report = makeReport();
            reports.push(report);

            for (var s = 0;s<day.orderedSessions.length;s++ ) {
                var session = day.orderedSessions[s];
                if ( doesSessionPassCompFilter(session,compositeFilter) ) {
                    validSessions[session.sessionId] = session;
                    numSessions++;
                    totalDuration += session.sessionLast - session.sessionStart;
                    totalEngagement += session.numEngagement;
                    report.addUser(session.userId);
                    summaryReport.addUser(session.userId);

                } 
            }

            for (var p = 0;p<day.orderedPages.length;p++ ) {
                var page = day.orderedPages[p];
                if ( validSessions[page.session.sessionId]){
                    numPages++;
                } 
            }

            report.rowTitle = day.date.toString();
            //report.users = day.orderedUsers.length; unique users set above
            report.pages = numPages;
            report.sessions = numSessions;
            report.engagement = totalEngagement;
            report.duration = totalDuration;

            // summaryReport.users += report.users;unique users updated above
            summaryReport.pages += report.pages;
            summaryReport.sessions += report.sessions;
            summaryReport.engagement += report.engagement;
            summaryReport.duration += report.duration;

        }

        reports.push(summaryReport);
        for (var r = 0;r<reports.length;r++) {
            var report = reports[r];
            addReportRow(rows,[
                report.rowTitle ,
                report.users,
                report.pages,
                report.sessions,
                report.engagement,
                Math.ceil(report.duration/1000), 
            ]);
        }

    }

    var headerRow = makeReportHeader([
        "Date",
        "Number of Users",
        "Number of Pages",
        "Number of Sessions",
        "Total Engagement",
        "Total Duration (s)"
    ]);

    return (<div>
        <CompFilterEditor className="my-2" value={getFilter()} report = {results} onChange={value=>{setCookie(filterCookie,value);setFilter(value)}} ></CompFilterEditor>
        <h2> Days</h2>
        <p>This table shows information about all the sessions and page views in the selected period which match your filter criteria. </p>
        <ul>
        <li>Total Number of Users : {summaryReport.users}</li>
        <li>Total Number of Sessions : {summaryReport.sessions}</li>
        <li>Total Number of Page Visits : {summaryReport.pages}</li>
        <li>Average Session Length : {format2dp((summaryReport.duration / summaryReport.sessions)/1000)} S</li>
        <li>Average Session Engagement : {format2dp(summaryReport.engagement / summaryReport.sessions)}</li>
        <li>Average Pages Visits Per Session : {format2dp( summaryReport.pages / summaryReport.sessions)}</li>
        <li>Average Page Visit Length : {format2dp( (summaryReport.duration / summaryReport.pages)/1000)} S</li>
        <li>Average Page Engagement : {format2dp( summaryReport.engagement / summaryReport.pages)}</li>
        </ul>
        <table className="border">
            <thead>
                {headerRow}
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    </div>)
}