
import { shallowEqual, useSelector } from "react-redux";
import { getCookie, getQueryParams, setCookie, splitCommaSeparatedList, useProperty } from "./general";
import { sendCommand } from "./Command";
import {  useEffect, useRef,useState ,useContext } from "react";
import {  addReportRow, formatAvgWithStability, formatPcntWithStability, makeEventReport, makeReportHeader } from "./events";
import { ColumnSelector } from "./ColumnSelector";
import { MDBBtn, MDBCheckbox, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";
import { showDialog } from "./ModalDialog";
import { UxRaw } from "./us_raw";
import $ from "jquery";
import { ExclusiveSelector } from "./ExlusiveSelector";
import { FilterPair , FilterEditor, doesSessionPassCompFilter, CompFilterEditor, makeEmptyFilter, parseCompFilterString, getCompositeFilter} from "./FilterPair";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryStack, VictoryTheme } from "victory";
import { Distribution } from "./distributionChart";

export function UxSessionsReporter(props){

    var {accountId,period} = props;

    const firstTime = useRef(true);
    
    const uxSessionsCols = getCookie('uxSessionsCols');
    const [getChosenColumns,setChosenColumns] = useProperty(uxSessionsCols)

    const filterCookie = 'uxCFilter';
    var filterString = getCookie(filterCookie);
    var emptyFilter = makeEmptyFilter();
    filterString = filterString?filterString:(JSON.stringify(emptyFilter));
    var [getFilter,setFilter] = useProperty(filterString)


    var events = useSelector(state => {
        var model = (state.model)?state.model:{};
        return model.events?model.events:[];
    },shallowEqual);

    
    var chartValues = [];
    var chartDurationValues = [];
    var chartName = "Engagements";

    
    var columns = [
        "Date",
        "Time",
        "User Id",
        "Length (s)",
        "Tot Pages",
        ["Pages",'15em'],
        "Referrer",
        ["Landing Page",'15em'],
        "Engagement",
        "Campaign",
        "AB",
        "Answers",
        // "addClickId",
        "adGroup",
        "Search Term",
        "raw",
    ]

    var rows =[];
    var referers = [];
    var campaigns = [];

    
    var csvRef = {csv};
    var csv = csvRef.csv;

    var avgPages ;
    var avgDuration;
    var avgEngagement;
    var pcntBounced;
    var sessions = [];

    if (events){
        var results = makeEventReport(events,period);
        
        referers = results.orderedReferers;
        campaigns = results.orderedCampaigns;

        campaigns.push('None');

        var compositeFilter = getCompositeFilter(getFilter(),results);


        // clean chosen
        for (var s=0;s < results.orderedSessions.length;s++){
            (function(session){

                if (doesSessionPassCompFilter(session,compositeFilter)) {


                    sessions.push(session);

                    var pages = "";
                    var addedByName ={};
                    for (var p = 0;p<session.orderedPages.length;p++){
                        var page = session.orderedPages[p];
                        if (!addedByName[page.pageName]){
                            addedByName[page.pageName] = page;
                            pages = pages?(pages+": "):"";
                            pages += page.pageName;
                        }
                    }


                    chartValues.push(session.numEngagement?session.numEngagement:0);
                    
                    chartDurationValues.push(session.sessionDuration?session.sessionDuration/1000:0);
                }
            })(results.orderedSessions[s])

        }

        avgPages = formatAvgWithStability(results,true,sessions,session=>{
             return session.orderedPages.length;
        });

        avgDuration = formatAvgWithStability(results,true,sessions,session=>{return session.sessionDuration/1000;});

        avgEngagement = formatAvgWithStability(results,true,sessions,'numEngagement');

        pcntBounced = formatPcntWithStability(results,true,sessions,session=>{return session.orderedPages.length == 1;});

        

    }

    function onFilterChanged(filterString){
        setCookie(filterCookie,filterString);
        setFilter(filterString)
    }


      
      

    return (<div>

        <CompFilterEditor  value={getFilter()} 
            report = {results}

            onChange = {onFilterChanged}
        ></CompFilterEditor> 

        <h2>Session Report ( {sessions.length} )</h2>

         <table className="">
            <tbody>
                <tr><td>Average pages per session</td><td>{avgPages}</td></tr>
                <tr><td>Average session length (s).</td><td>{avgDuration}</td></tr>
                <tr><td>Average session engagement.</td><td>{avgEngagement}</td></tr>
                <tr><td>Percent of sessions bounced.</td><td>{pcntBounced}</td></tr>
            </tbody>
        </table>
        <br/>
        <Distribution
            key = "1"
            style ={{
                width : '600px',
                border : '1px solid',
            }}

            values = {chartValues}
            bucketWidth = {1}
            name =  'Engagements'
            title = 'Engagements per Session'
        /> 
        <Distribution
            key = "2"
            style ={{
                width : '600px',
                
                border : '1px solid',
            }}

            values = {chartDurationValues}
            bucketWidth = {1}
            name = 'Duration (s)' 
            title = 'Session Durations'
        /> 
        
    </div>)
}
