import {checkAll,getQueryParams,getCookie,setCookie,deleteCookie,split} from './general';
import {sendCommand , ensureCmdArgs } from './Command';
import { showDialog,hideDialog,Dialog } from './ModalDialog';
import {BaseContext} from './baseContext';
import {CreateNewAcount,ChooseNewPassword,PasswordResetRequest as PasswordResetRequest,Logon,ConfirmEmail} from './Accounts';

import React, { useEffect, useRef,useState ,useContext} from 'react';
import { useSelector} from 'react-redux';
import {Container,Row,Col,Button,Modal} from 'react-bootstrap';

import $ from 'jquery';
import { ContextMenu } from './ContextMenu';

function showFieldValidation(checks){

    var error = checkAll(checks);
    
    if (error){
        showDialog({
            title:'There is a problem',
            message : error,
            ok : true
        })
        return false;
    } else {
        return true;
    }

}

export function FrontEnd(props){

    const { FrontFrame } = props;

    const [ stage , setStage ] = useState({tag:'div',props:{}});
    const [ stageCurtain , setStageCurtain ] = useState({tag: null,props:{}});
    
    const firstTime = useRef(true);
    const targetEventId = useRef(-1);

    const loggedInUserId = useRef(-1);

    useEffect( () => {

        if ( firstTime.current ) {

            const url = window.location.href;

            firstTime.current = false;

            const params = getQueryParams();

            const securityToken = params.token;
            const cmd = params.cmd;
            targetEventId.current = params.group;

            function onAuthorizationCallSucceeded(authReport){
                
                if ( !authReport.report.ok ) {
                    clearLogon();
                    setStage({ tag : Logon, props : {}});
                } else {
                    const userId = parseInt(authReport.userId);
                    onAuthorizedUserIdentified(userId);
                }
            }
            
            if (params.email){ // DEVELOPMENT - should not allow looking at emails like this on production version
                // setStage({ tag : EmailBodge , props : {}});
            } else {
                if (cmd=='reset'){
                    setStage({ tag : ChooseNewPassword, props : {securityToken}});

                } else if (cmd=='confirm'){
                    setStage({ tag : ConfirmEmail, props : {securityToken}});
    
                } else {

                    if (true){
                        setStage({ tag : FrontFrame, props : {poolId:6 }});// 74/6

                    // the sessionToken is in the cookies if it is anywhere 
                    // this call asks the server to decode the userId from any session token....
                    // ...and checks the user exists in useable form
                    } else if ( getCookie('HostDiag') ){
                        command({
                            actions:[{
                                objectType : 'Account', verb : 'rejoinSession',
                                onSucceeded : onAuthorizationCallSucceeded
                            }],
                            modal : true
                    });
                    } else {
                        setStage({ tag : Logon, props : {}});
                    }
                }
            }
        }
    });
    
    function clearLogon(){
        loggedInUserId.content = -1;
        deleteCookie('HostDiag');
    }

    function command(args){

        args = ensureCmdArgs(args);

        const storedOnCompleted = args.onCompleted;

        sendCommand ( $.extend({},args,{

            loggedInUserId : loggedInUserId.content, 

            onCompleted : (type)=> {

                if (type=='authFailure') {
                    clearLogon();
                    setStage({ tag : Logon, props : {}})
                }

                if ( storedOnCompleted ) {
                    storedOnCompleted(type);
                }
            },
        }));
    }

    function onAuthorizedUserIdentified(userId){
        loggedInUserId.content = userId;
        
    }

    const systemCallbacks = {

        command : command,

        showDialog : showDialog,

        showFieldValidation : showFieldValidation,

        showStageCurtain : (curtain)=>{
            setStageCurtain(curtain);
        },
        
        stageCurtainFinished : (curtain)=>{
            setStageCurtain({tag: null,props:{}});
        },

        onVerificationEmailResendRequested : (email)=>{
            command({
                action: {
                    objectType : 'Account', verb : 'reverify',
                    email : email
                },
                onSucceeded : (result)=>{
                    if (result.ok) {
                        showDialog({
                            title : 'Success',
                            message : 'An account verification link has been sent to ' + email,
                            ok : 'close'
                        });
                    } else {
                        showDialog({
                            title : 'Failed',
                            message : 'There was a problem at the server. Please try again later.',
                            ok : 'close'
                        })
                    }
                }
            })
        },

        onLogonSucceeded : (userId,sessionToken)=>{
            setCookie('HostDiag',sessionToken);
            onAuthorizedUserIdentified(userId);
        },

        onPasswordResetRequested : () =>{
            loggedInUserId.content = -1;
            setStage({ tag : PasswordResetRequest, props : {} });
        },

        onEmailConfirmationNeedsPassword : (securityToken)=>{
            setStage( { tag : ChooseNewPassword, props : {securityToken} });
        },

        onPasswordResetSucceeded : (userId,password) => {
            setStage({ tag : Logon, props : {}});
        },

        onNewAccountRequested : ()=>{
            setStage({ tag : CreateNewAcount, props : {}});
        },

        onNewAccountCreated : (email) => {
            // tell user that account creation was succesfull and that email has been sent
            setStage({ tag : Logon, props : {}});
        },

        onLogOffRequested : ()=> {
            clearLogon();
            setStage({ tag : Logon, props : {}});
        },

        onRenterFrontEnd : ()=> {
            if (loggedInUserId.content>=0){
                setStage({ tag : FrontFrame, props : { userId : loggedInUserId.content}});
            } else {
                setStage({ tag : Logon, props : {}});
            } 
        }

    }

    const StageTag = React.createElement(
        stage.tag,
        stage.props
    );
    
    const StageCurtainTag = stageCurtain.tag?React.createElement(
        stageCurtain.tag,
        stageCurtain.props?stageCurtain.props:{}
      ):null;

    const stageWrapperClass = StageCurtainTag?"d-none":"";

    return (<BaseContext.Provider value={systemCallbacks}  >
        
        <div  style={{
                height : '100vh',
                width : '100%'

            }} 
            className = "m-0 p-0"
        >
            <div className={stageWrapperClass} style={{height : '100vh',width : '100%'}}>
                {StageTag}
            </div>
            {StageCurtainTag}
        </div>

        <Dialog /> 
        <ContextMenu /> 
        
    </BaseContext.Provider>)

}

