import { MDBCheckbox } from "mdb-react-ui-kit";
import { arrayToNamed, getCookie, isString, setCookie, splitCommaSeparatedList, useProperty, useParameter, CheckBox } from "./general";
import $ from "jquery";


export  function OptionSelector(props){

    var {style,className,referers,value,onChange} = props;

    referers = referers?referers:[];
    
    var [getValue,setValue] = useParameter(value);

    var chosen = getChosen(); 

    var checkboxes = [];
    for (var c =0;c<referers.length;c++){
        (function(referer){
            checkboxes.push(
                <div  key = {referer} className='p-1  m-1' style={{display:'inline-block'}} >
                <CheckBox
                    label= {referer}
                    value={!!chosen[referer]}
                    onChange={()=>reviewValue(referer)}
                />
                </div >
                
            );
    
        })(referers[c])
    }

    function reviewValue(referer){
        var chosen = getChosen();
        if (chosen[referer]){
            delete(chosen[referer]); 
        } else {
            chosen[referer]=referer;
        }
        var newValue = [];
        for (var prop in chosen) {
            if (Object.prototype.hasOwnProperty.call(chosen, prop)) {
                newValue.push(prop);
            }
        }

        var value = newValue.join(',')
        setValue(value);
        if (onChange){
            onChange(value);
        }
    }

    function getChosen(){

        var [list,dictionary] =  splitCommaSeparatedList(getValue());
        return dictionary;
    }

    return <div style={style} className={className}>
        {checkboxes}
    </div>

}



export  function ExclOptionSelector(props){

    var {style,className,values,value,onChange} = props;

    var [getValue,setValue] = useParameter(value);

    var chosen = getValue();

    var checkboxes = [];
    for (var v =0;v<values.length;v++){
        (function(value){
            checkboxes.push(
                <div  key = {value} className='p-1  m-1' style={{display:'inline-block'}} >
                <CheckBox
                    label= {value}
                    value={value==chosen}
                    onChange={()=>reviewValue(value)}
                />
                </div >
                
            );
    
        })(values[v])
    }

    function reviewValue(value){
        var chosen = getValue();
        if (chosen != value){
            setValue(value);
            if (onChange){
                onChange(value);
            }    
        }
    }

    return <div style={style} className={className}>
        {checkboxes}
    </div>

}